import { forwardRef, MouseEventHandler, ReactNode, useState } from 'react'
import styled from 'styled-components/macro'
import { AlignSelfType } from 'grommet/utils'
import { Placement } from '@popperjs/core'

import { themeColor } from '../../theme'
import { Box, BoxProps } from '../../layout'
import { ListItemContainer, ListItemExpandableContent } from './list-item-box'
import { IconButton } from '../../button'
import { Icon } from '../../icon'
import { Menu, MenuListItem, MenuListItemProps } from '../../menu'
import { Text } from '../../typography'

export type ListItemProps = {
  size?: 'small' | 'medium' | 'large'
  button?: boolean
  expandable?: boolean
  expandableContent?: ReactNode
  startComponent?: ReactNode
  endComponents?: ReactNode[] | ReactNode
  menuOptions?: MenuListItemProps[]
  onClickRemove?: () => void
  onClickRemoveTooltip?: string
  onClickRemoveTooltipPlacement?: Placement
  onClick?: (e: any) => void
  onMouseDown?: MouseEventHandler
  linkTo?: string
  a11yTitle?: string
  children?: ReactNode
  className?: string
  selected?: boolean
  disabled?: boolean
  active?: boolean
  hasNext?: boolean
  background?: BoxProps['background']
  border?: boolean
  width?: BoxProps['width']
  level?: number
  prominence?: 'high' | 'default' | 'low'
  tabIndex?: number
  'data-testid'?: string
  role?: string
  title?: string
  subTitle?: string
  /** Controls the expanded state of the list item. */
  open?: boolean
  alignCloseButton?: AlignSelfType
  hasError?: boolean
  clickable?: boolean
}

// TODO: Revisit layout solution, consider using grid to align header title to disclosable content.
// TODO: When subheader is present, 'small' size layout does not provide enough height in header.
export const ListItem = forwardRef<HTMLElement, ListItemProps>(
  (
    {
      size,
      hasNext,
      button,
      expandable = false,
      expandableContent,
      startComponent,
      menuOptions,
      onClickRemove,
      onClickRemoveTooltip,
      onClickRemoveTooltipPlacement = 'top',
      onClick,
      onMouseDown,
      children,
      selected,
      disabled,
      active,
      className,
      level = 1,
      background,
      border,
      linkTo,
      title,
      subTitle,
      width,
      tabIndex,
      a11yTitle,
      prominence,
      'data-testid': dataTestId,
      role,
      open: controlledOpen,
      alignCloseButton = 'center',
      hasError,
      clickable,
      ...props
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false)
    const hasEndComponents = Array.isArray(props.endComponents) ? props.endComponents.length > 0 : !!props.endComponents

    const endComponents = hasEndComponents ? (
      !Array.isArray(props.endComponents) ? (
        props.endComponents
      ) : (
        <>
          {/* @ts-ignore */}
          {props.endComponents?.map((endComponent, i) => {
            if (endComponent) {
              return <Box key={i}>{endComponent}</Box>
            }
          })}
        </>
      )
    ) : null

    const handleClick = (e: MouseEvent) => {
      onClick?.(e)
      if (expandable) {
        handleExpandToggle()
      }
    }

    const handleExpandToggle = () => {
      setIsOpen(!isOpen)
    }

    const expandToggle = (
      <Box direction="row" gap="xxsmall" align="center" onClick={handleExpandToggle}>
        <ExpandCollapseIcon $isOpen={controlledOpen ?? isOpen} />
      </Box>
    )
    const removeButtonLabel = onClickRemoveTooltip ?? 'Remove ' + (a11yTitle ?? title ?? '').trim()

    return (
      <>
        <ListItemContainer
          ref={ref as any} // can be whatever type the list item is -- div, button, link
          data-testid={dataTestId}
          role={role}
          tabIndex={tabIndex}
          tag={button ? 'button' : undefined}
          onClick={onClick || expandable ? handleClick : undefined}
          onMouseDown={onMouseDown}
          className={className}
          size={size}
          selected={selected}
          active={active}
          background={background} // NOTE should try to phase this out as we dont want custom BG
          border={border}
          title={title}
          width={width}
          a11yTitle={a11yTitle}
          clickable={!!onClick || !!linkTo || !!button || clickable}
          level={level}
          // Should be based on size prop
          css={`
            opacity: ${disabled || prominence === 'low' ? 0.62 : 1};
            pointer-events: ${disabled ? 'none' : 'auto'};
            &:hover {
              .circular-motion {
                background-color: ${themeColor('bg-1')};
                width: 15px;
              }
            }
          `}
        >
          {expandable && expandToggle}
          {startComponent && <StartComponentBox flex={false}>{startComponent}</StartComponentBox>}

          <Box
            css={`
              flex: 1;
            `}
            alignSelf="center"
          >
            <Text truncate tag="div" weight={prominence === 'high' ? 600 : 400} color={hasError ? 'error' : 'text'}>
              {children || title}
            </Text>
            {subTitle && (
              <Text truncate tag="div" size="xsmall" color={hasError ? 'error' : 'text'}>
                {subTitle}
              </Text>
            )}
          </Box>
          {endComponents}
          {menuOptions && menuOptions.length && (
            <Menu
              align="end"
              onClick={e => e.stopPropagation()}
              trigger={
                <IconButton
                  icon="more-vertical"
                  disabled={disabled}
                  disableTooltip //for now until it stops wrapping in a span
                  label="More options"
                  size={size}
                  onClick={e => e.stopPropagation()}
                />
              }
            >
              {menuOptions.map((option, i) => {
                return (
                  <MenuListItem
                    key={`option-${i}`}
                    label={option.label}
                    icon={option.icon}
                    value={option.label}
                    onClick={option.onClick}
                  />
                )
              })}
            </Menu>
          )}
          {onClickRemove && (
            <IconButton
              icon="close"
              onClick={event => {
                event.stopPropagation()
                onClickRemove()
              }}
              disabled={disabled}
              alignSelf={alignCloseButton}
              tip={removeButtonLabel}
              tipPlacement={onClickRemoveTooltipPlacement}
              label={removeButtonLabel}
              size={size}
            />
          )}

          {hasNext && <Icon icon="chevron-right" color="text-light" />}
        </ListItemContainer>
        {expandable && (controlledOpen ?? isOpen) && (
          <ListItemExpandableContent listItemSize={size} listItemLevel={level + 1}>
            {expandableContent}
          </ListItemExpandableContent>
        )}
      </>
    )
  }
)

export const StartComponentBox = styled(Box)``

type ExpandCollapseIconProps = {
  $isOpen?: boolean
}

const ExpandCollapseIcon = styled(Icon).attrs(() => ({
  icon: 'caret-right'
}))<ExpandCollapseIconProps>`
  transition: transform 200ms ease;
  transform: rotate(${props => (props.$isOpen ? '90deg' : '0deg')});
`
