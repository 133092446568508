import { memo, useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { Badge, MultiSelectControl } from '@cutover/react-ui'
import { usersState, userTasksCountState } from 'main/recoil/runbook'
import { FilterAccordionPanel } from '../shared/filter-accordion-panel'
import { useRunbookUserFilter } from 'main/recoil/data-access'

export const UserGroup = memo(() => {
  const [value, setValue] = useRunbookUserFilter()
  const users = useRecoilValue(usersState)
  const userTasksCount = useRecoilValue(userTasksCountState)
  const options = useMemo(
    () =>
      users.map(user => ({
        label: user.name,
        value: user.id,
        suffix: <Badge label={userTasksCount[user.id] ?? 0} textColor="white" color={user.color} />
      })),
    [userTasksCount, users]
  )

  return users?.length ? (
    <FilterAccordionPanel label="User" applied={value && !!value.length}>
      <MultiSelectControl
        a11yTitle="User"
        value={(value as any[])?.filter(v => v?.value !== 0 && v?.value !== '*' && v?.value !== null)}
        onChange={val => {
          // need to preserve none checked if it was also selected
          if (!val || val.length === 0) {
            setValue(prevVal => (prevVal?.includes(0) ? [0] : undefined))
          } else {
            const nextVals = val?.map(v => v.value)
            setValue(prevVal => (prevVal?.includes(0) ? [0, ...nextVals] : nextVals))
          }
        }}
        options={options}
        plain
      />
    </FilterAccordionPanel>
  ) : null
})
