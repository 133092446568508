import { useCallback } from 'react'
import { SetterOrUpdater } from 'recoil'
import { uniqBy } from 'lodash'

import { useNotify } from '@cutover/react-ui'
import { AppContexts, AppsChannelResponse, AppView, ContentNode } from 'main/components/apps/apps-types'

export const operations = ['insert', 'update', 'update_in_place', 'delete', 'notifications']

export const useComponentPropsStateHandler = () => {
  const notification = useNotifications()
  return useCallback(
    ({
      response,
      context,
      setComponentProps
    }: {
      response: AppsChannelResponse
      context: string
      setComponentProps: SetterOrUpdater<AppContexts>
    }) => {
      const {
        initializeComponentProps,
        insertComponentProps,
        updateComponentProps,
        updateInPlaceComponentProps,
        deleteComponentProps
      } = componentPropsHelper(setComponentProps)
      const type = response.view.type
      const content = response.view.content || []
      const id = response.view.id

      if (operations.includes(type)) {
        switch (type) {
          case 'insert':
            insertComponentProps({ content, nodeId: id, context })
            break
          case 'update':
            updateComponentProps({ content, nodeId: id, context })
            break
          case 'update_in_place':
            updateInPlaceComponentProps({ content, nodeId: id, context })
            break
          case 'delete':
            deleteComponentProps({ content, nodeId: id, context })
            break
          case 'notifications':
            notification(response.view)
            break
        }
      } else {
        initializeComponentProps({ content, context })
      }
    },
    []
  )
}

type notificationNodeType = ContentNode & {
  kind: string
  title: string
  message: string
}

const useNotifications = () => {
  const notify = useNotify()

  return useCallback((view: AppView) => {
    const content = (view.content || []) as notificationNodeType[]
    content.forEach(notification => {
      switch (notification.kind) {
        case 'success':
          notify.success(notification.message, { title: notification.title })
          break
        case 'warning':
          notify.warning(notification.message, { title: notification.title })
          break
        case 'error':
          notify.error(notification.message, { title: notification.title })
          break
        case 'info': // not implemented in schema as its gray
          notify.info(notification.message, { title: notification.title })
          break
      }
    })
  }, [])
}

type ComponentPropsOperationType = {
  content: ContentNode[]
  nodeId?: string
  context: string
}

const componentPropsHelper = (setComponentProps: SetterOrUpdater<AppContexts>) => {
  /**
   * Recursively parses the content tree and initializes the AppContexts atom with any node containing an id prop.
   * If the id prop is found, the whole child structure is stored in state and children are not parsed.
   */
  const initializeComponentProps = ({ content, context }: ComponentPropsOperationType) => {
    content.map((node: ContentNode) => {
      const { id, ...props } = node
      if (id) {
        setComponentProps(appContexts => {
          const prevContext = appContexts[context]
          const updatedContext = { ...prevContext, [id]: { ...props } }
          return { ...appContexts, [context]: updatedContext }
        })
      } else if (node.content) {
        initializeComponentProps({ content: node.content, context })
      }
    })
  }

  /**
   * Appends all new nodes to the content array of the identified component.
   */
  const insertComponentProps = ({ content, nodeId, context }: ComponentPropsOperationType) => {
    if (nodeId) {
      setComponentProps(appContexts => {
        const prevContext = appContexts[context]
        if (prevContext) {
          const prevComponent = prevContext[nodeId]
          const prevContent = prevComponent?.content || []
          const updatedContent = uniqBy([...prevContent, ...content], 'id')
          const updatedComponent = { ...prevComponent, content: [...updatedContent] }
          const updatedContext = { ...prevContext, [nodeId]: { ...updatedComponent } }
          return { ...appContexts, [context]: updatedContext }
        } else {
          return appContexts
        }
      })
    }
  }

  /**
   * When an id is passed, replaces the content node array of the identified component props. Same as delete all content and insert.
   * When no id is passed, goes through the new content and replaces each component in the context.
   */
  const updateComponentProps = ({ content, nodeId, context }: ComponentPropsOperationType) => {
    // If root view has an id, update its content
    if (nodeId) {
      setComponentProps(appContexts => {
        const prevContext = appContexts[context]
        if (prevContext) {
          const prevComponent = prevContext[nodeId]
          const updatedComponent = { ...prevComponent, content: content as ContentNode[] }
          const updatedContext = { ...prevContext, [nodeId]: updatedComponent }
          return { ...appContexts, [context]: updatedContext }
        } else {
          return appContexts
        }
      })
      // Otherwise, iterate through content nodes
    } else {
      content.map(node => {
        const { id } = node
        if (id) {
          setComponentProps(appContexts => {
            const prevContext = appContexts[context]
            if (prevContext) {
              const prevComponent = prevContext[id]
              const updatedComponent = { ...prevComponent, ...node }
              const updatedContext = { ...prevContext, [id]: updatedComponent }
              return { ...appContexts, [context]: updatedContext }
            } else {
              return appContexts
            }
          })
        }
      })
    }
  }

  /**
   * @deprecated - Can be replaced by using updateComponentProps without an id and passing the node as content.
   *
   * Updates only nodes in identified component's content that have matching child ids.
   */
  const updateInPlaceComponentProps = ({ content, nodeId, context }: ComponentPropsOperationType) => {
    if (nodeId) {
      setComponentProps(appContexts => {
        const prevContext = appContexts[context]
        if (prevContext) {
          const prevComponent = prevContext[nodeId]
          const prevContent = prevComponent?.content || []

          const updatedContent = [...(prevContent as ContentNode[])]
          for (let i = 0; i < updatedContent.length; i++) {
            const existingNode = updatedContent[i]
            const newNode = content.find((node: ContentNode) => node.id === existingNode.id)
            if (newNode) {
              updatedContent[i] = newNode
            }
          }

          const updatedComponent = { ...prevComponent, content: updatedContent }
          const updatedContext = { ...prevContext, [nodeId]: updatedComponent }
          return { ...appContexts, [context]: updatedContext }
        } else {
          return appContexts
        }
      })
    }
  }

  /**
   * Deletes only those nodes identified the component's content that have matching child ids.
   */
  const deleteComponentProps = ({ content, nodeId, context }: ComponentPropsOperationType) => {
    if (nodeId) {
      setComponentProps(appContexts => {
        const prevContext = appContexts[context]
        if (prevContext) {
          const prevComponent = prevContext[nodeId]
          const prevContent = prevComponent?.content || []
          const updatedContent = [...(prevContent as ContentNode[])]
          const idsToDelete = content.map(node => node.id)
          for (let i = 0; i < updatedContent.length; i++) {
            if (idsToDelete.includes(updatedContent[i].id)) {
              updatedContent.splice(i, 1)
            }
          }
          const updatedComponent = { ...prevComponent, content: [...updatedContent] }
          const updatedContext = { ...prevContext, [nodeId]: updatedComponent }
          return { ...appContexts, [context]: updatedContext }
        } else {
          return appContexts
        }
      })
    }
  }

  return {
    initializeComponentProps,
    insertComponentProps,
    updateComponentProps,
    updateInPlaceComponentProps,
    deleteComponentProps
  }
}
