import { useMemo } from 'react'
import { format, fromUnixTime } from 'date-fns'

import { Box, Message, Modal, Text } from '@cutover/react-ui'
import {
  useAccountCustomFields,
  useAccountProperty,
  useCurrentUser,
  useRunbook,
  useRunbookId,
  useRunbookPermission,
  useSetModalActiveState,
  useTaskListTask,
  useTaskProgression,
  useTaskProgressionState,
  useTaskTypeOrDefault
} from 'main/recoil/data-access'
import { useLanguage } from 'main/services/hooks'
import { useModalCheckTaskStageChanged } from './use-modal-check-task-stage-changed'

type TaskFixedStartOverrideProps = {
  id: number
  onClose: () => void
}

export const TaskFixedStartOverride = ({ id, onClose }: TaskFixedStartOverrideProps) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'taskFixedStartOverrideModal' })
  const canUpdate = useRunbookPermission({ attribute: 'update' })
  const task = useTaskListTask(id)
  const taskType = useTaskTypeOrDefault(task.task_type_id)
  const startFixedTime = format(fromUnixTime(task.start_fixed as number), 'dd/MM/yy HH:mm')
  const { modalClose, modalContinue } = useSetModalActiveState()
  const { resolveProgressionModalCallback, startOrFinishTask } = useTaskProgression()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const progressionState = useTaskProgressionState(id)! // if we've gotten to this modal then there has to be a progression state
  const accountName = useAccountProperty({ attribute: 'name' })
  const customFields = useAccountCustomFields()
  const currentUser = useCurrentUser()
  const runbookId = useRunbookId()
  const runbook = useRunbook()

  const nextModal = useMemo(
    () =>
      resolveProgressionModalCallback({
        task,
        taskType,
        accountName,
        progressionState,
        customFields,
        currentUser,
        runbook,
        from: { id, type: 'task-override-fixed-start' }
      }),
    [
      task,
      taskType,
      accountName,
      progressionState,
      customFields,
      currentUser,
      runbookId,
      resolveProgressionModalCallback
    ]
  )

  const {
    handleSubmit,
    handleClose,
    confirmIcon,
    confirmText,
    errors: stageChangedErrors,
    renderContent,
    hasCancelButton
  } = useModalCheckTaskStageChanged({
    task,
    onSubmit: async () => {
      if (!canUpdate) return modalClose()
      return nextModal ? modalContinue(nextModal, { id, type: 'task-override-fixed-start' }) : startOrFinishTask(id)
    },
    onClose,
    confirmIcon: canUpdate ? 'caret-right' : undefined,
    confirmText: t(canUpdate ? 'confirmTextAdmin' : 'confirmText')
  })

  return (
    <Modal
      data-testid="modal-task-override-start-fixed"
      open
      animate={nextModal ? 'in' : true}
      title={t('title')}
      confirmText={confirmText as string}
      confirmIcon={confirmIcon}
      onClickConfirm={handleSubmit as any}
      onClose={handleClose}
      hasCancelButton={hasCancelButton}
    >
      {stageChangedErrors && <Message margin type="error" message={stageChangedErrors} />}
      {renderContent(
        <Box>
          <Text>{t('content', { startFixedTime })}</Text>
          {!canUpdate && <Text>{t('contentNotAdmin')}</Text>}
        </Box>
      )}
    </Modal>
  )
}
