import { useCallback } from 'react'
import { useRecoilCallback, useRecoilTransaction_UNSTABLE } from 'recoil'
import { produce } from 'immer'
import { extend } from 'lodash'

import {
  RunbookMergeResponse,
  RunbookResponse,
  RunbookUpdateResponse
} from 'main/services/api/data-providers/runbook-types'
import {
  runbookResponseState_INTERNAL,
  runbookVersionResponseState_INTERNAL,
  taskListResponseState_INTERNAL
} from '../../runbook/models'
import { updateAllChangedTasks, updateRunbookData } from './shared-updates'
import { RunbookShowRunbook } from 'main/services/queries/types'
import { getTasks, TaskListResponseType } from 'main/services/queries/use-tasks'
import { getRunbookVersion, GetRunbookVersionResponse } from 'main/services/queries/use-runbook-versions'

export const useProcessRunbookResponse = () => {
  const processRunbookMergeResponse = useProcessRunbookMergeResponse()
  const processRunbookUpdateResponse = useProcessRunbookUpdateResponse()

  return useCallback(
    (response: RunbookResponse) => {
      switch (response?.meta.headers.request_class) {
        case 'Runbook':
          switch (response.meta.headers.request_method) {
            case 'update':
              processRunbookUpdateResponse(response as RunbookUpdateResponse)
              break
            case 'merge':
              processRunbookMergeResponse(response as RunbookMergeResponse)
              break
            default:
              return
          }
      }
    },
    [processRunbookMergeResponse, processRunbookUpdateResponse]
  )
}

export const useProcessRunbookUpdateResponse = () => {
  return useRecoilTransaction_UNSTABLE(transactionInterface => (response: RunbookUpdateResponse) => {
    updateRunbookData(transactionInterface)(response.runbook)
    updateAllChangedTasks(transactionInterface)(response.meta.changed_tasks)
  })
}

export const useProcessRunbookMergeResponse = () => {
  const updatePageData = useRecoilTransaction_UNSTABLE(
    ({ set }) =>
      ({
        taskData,
        runbookVersionData,
        runbookData
      }: {
        taskData: TaskListResponseType
        runbookVersionData: GetRunbookVersionResponse
        runbookData: RunbookShowRunbook
      }) => {
        set(
          taskListResponseState_INTERNAL,
          produce(taskData, draft => {
            extend(draft, taskData)
          })
        )

        set(
          runbookVersionResponseState_INTERNAL,
          produce(runbookVersionData, draft => {
            extend(draft, runbookVersionData)
          })
        )

        set(runbookResponseState_INTERNAL, prev =>
          produce(prev, draft => {
            extend(draft.runbook, runbookData)
          })
        )
      }
  )

  return useRecoilCallback(() => async (response: RunbookMergeResponse) => {
    const responseRunbook = response.runbook
    const responseRunbookId = responseRunbook.id
    const responseRunbookCurrentVersionId = responseRunbook.current_version?.id || responseRunbook.runbook_version_id

    const [taskData, runbookVersionData] = await Promise.all([
      getTasks(responseRunbookId, responseRunbookCurrentVersionId),
      getRunbookVersion(responseRunbookId, responseRunbookCurrentVersionId)
    ])

    updatePageData({
      taskData,
      runbookVersionData,
      runbookData: responseRunbook
    })
  })
}
