import { useEffect, useRef, useState } from 'react'
import { useRecoilValue, useResetRecoilState } from 'recoil'
import { useMatch } from 'react-router-dom'

import { Box } from '@cutover/react-ui'
import { useToggleFilterPanel } from 'main/components/layout/layout-hooks'
import {
  FilterPanelToggleButton,
  SearchType,
  SubHeader,
  SubHeaderButton,
  SubHeaderResultsText,
  SubHeaderSearch
} from 'main/components/layout/shared/sub-header'
import { useLanguage } from 'main/services/hooks'
import { taskListCountState } from 'main/recoil/runbook'
import { filterCount, filterSelector, hasFiltersState } from 'main/recoil/shared/filters'
import { useActiveTasksFilter, useMyTasksFilter, useSearchQueryFilter } from 'main/recoil/data-access'
import { SaveFilterModal } from '../modals/save-filter-modal'
import { runState } from 'main/recoil/runbook/models/runbook-version/run'
import { DisplayType, ViewNavigation } from './view-navigation'

export const RunbookSubHeader = () => {
  const toggleFilterPanel = useToggleFilterPanel()
  const { t } = useLanguage('runbook', { keyPrefix: 'subHeader' })

  const numFilters = useRecoilValue(filterCount)
  const numTasks = useRecoilValue(taskListCountState)
  const [showSaveViewModal, setShowSaveViewModal] = useState(false)
  const [searchFilter, setSearchFilter] = useSearchQueryFilter()
  const [isFilteringMyTasks, setIsFilteringMyTasks] = useMyTasksFilter()
  const [isFilteringActiveTasks, setIsFilteringActiveTasks] = useActiveTasksFilter()
  const clearFilteringMyTasks = useResetRecoilState(filterSelector({ attribute: 'mt' }))
  const clearFilteringActiveTasks = useResetRecoilState(filterSelector({ attribute: 'at' }))
  const clearSearchQuery = useResetRecoilState(filterSelector({ attribute: 'q' }))
  const showSaveViewButton = useRecoilValue(hasFiltersState)
  const run = useRecoilValue(runState)
  const searchRef = useRef<SearchType>(null)
  const displayMatch = useMatch('/app/:accountId/runbooks/:runbookId/:runbookVersionId/tasks/:displayType/*')
  const currentPage = displayMatch?.params.displayType as DisplayType

  const handleSearch = (input?: string) => {
    if (input) {
      setSearchFilter(input)
    }
  }

  useEffect(() => {
    if (searchFilter === undefined) {
      searchRef.current?.clear()
    }
  }, [searchFilter])

  const handleToggleMyTasks = () => {
    if (isFilteringMyTasks) {
      clearFilteringMyTasks()
    } else {
      setIsFilteringMyTasks(true)
    }
  }

  const handleToggleActiveTasks = () => {
    if (isFilteringActiveTasks) {
      clearFilteringActiveTasks()
    } else {
      setIsFilteringActiveTasks(true)
    }
  }

  useEffect(() => {
    if (searchFilter === undefined) {
      searchRef.current?.clear()
    }
  }, [searchFilter])

  const handleClose = () => {
    if (searchFilter) {
      clearSearchQuery()
    }
  }

  return (
    <SubHeader.Items>
      <SubHeader.LeftItems>
        <Box width={{ min: '40px' }}>
          <FilterPanelToggleButton
            filterCount={numFilters}
            onClick={() => toggleFilterPanel()}
            onKeyUp={() => toggleFilterPanel()}
            tip={t('filterTasks')}
          />
        </Box>
        <SubHeaderSearch ref={searchRef} onSearch={handleSearch} onClose={handleClose} initialValue={searchFilter} />
        <SubHeaderButton onClick={() => handleToggleMyTasks()} active={!!isFilteringMyTasks} label={t('myTasks')} />
        {run?.mode === 'active' && (
          <SubHeaderButton
            onClick={() => handleToggleActiveTasks()}
            active={!!isFilteringActiveTasks}
            label={t('activeTasks')}
          />
        )}
        {showSaveViewButton && (
          <SubHeaderButton
            data-testid="save-filter"
            onClick={() => setShowSaveViewModal(true)}
            label={t('saveFilter')}
          />
        )}
        {numTasks !== undefined ? (
          <Box justify="center" margin={{ left: '4px' }}>
            <SubHeaderResultsText value={t('results', { amount: `${numTasks.filtered}/${numTasks.total}` })} />
          </Box>
        ) : null}
      </SubHeader.LeftItems>
      <SubHeader.RightItems>
        {currentPage !== 'react-dashboard' && <ViewNavigation />}
        {/* Replace with new <Menu comp <SubHeaderOptionsMenu menuOpen={menuOpen} toggleMenu={toggleMenu} dropdownMenuOptions={menuOptions} /> */}
      </SubHeader.RightItems>
      {showSaveViewModal && <SaveFilterModal closeModal={() => setShowSaveViewModal(false)} />}
    </SubHeader.Items>
  )
}
