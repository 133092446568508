import { atom } from 'recoil'

import { runbookAtom } from 'main/recoil/shared/recoil-state-runbook-decorators'

export const taskCreateFromPredecessorIdState_INTERNAL = runbookAtom<number | undefined>({
  key: 'tasks:add:predecessor-id',
  default: undefined
})

export const taskCreateNameState = atom<string | undefined>({
  key: 'tasks:add:name',
  default: undefined
})
