import { useCallback } from 'react'
import { AxiosInstance, InternalAxiosRequestConfig } from 'axios'

import { AppsPayload } from './apps-types'
import { createApiClient, setupClientDefaults } from 'main/services/api/api-client-unstable'
import { apiClient } from 'main/services/api'
import { RunbookIntegration, RunbookIntegrationActionItem } from 'main/services/queries/types'
import { useSetAppsConnectionLost } from 'main/recoil/data-access'

export type AvailableIntegrationsResponse = {
  integration_links: RunbookIntegration[]
}

type IntegrationActionItemResponse = {
  integration_action_item: RunbookIntegrationActionItem
}

export const appsApiClient = createApiClient()
setupClientDefaults(appsApiClient)
updateRequestDataPayload(appsApiClient)

export const usePostAppEvents = () => {
  const setConnectionLost = useSetAppsConnectionLost()

  return useCallback(async (payload: AppsPayload) => {
    try {
      return await appsApiClient.post('app_events', payload)
    } catch {
      setConnectionLost(true)
    }
  }, [])
}

export function addSessionIdToPayload(request: InternalAxiosRequestConfig) {
  request.data = request.data ?? {}
  let browserHash = window.sessionStorage.getItem('browserHash')

  if (!browserHash) {
    browserHash = Math.random().toString(14).slice(2)
    window.sessionStorage.setItem('browserHash', browserHash)
  }

  request.data.session_id = browserHash
  return request
}

export function updateRequestDataPayload(instance: AxiosInstance) {
  instance.interceptors.request.use(request => addSessionIdToPayload(request))
}

export const getAvailableIntegrations = async (runbookId: string): Promise<AvailableIntegrationsResponse> => {
  const { data } = await apiClient.get<AvailableIntegrationsResponse>({
    url: `runbooks/${runbookId}/runbook_versions/current_version/integrations`
  })
  return data
}

export const getIntegrationActionItem = async (
  integrationActionItemId: string
): Promise<IntegrationActionItemResponse> => {
  const { data } = await apiClient.get<IntegrationActionItemResponse>({
    url: `integration_action_items/${integrationActionItemId}`
  })
  return data
}
