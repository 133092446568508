import { memo, useEffect, useState } from 'react'

import { useNotify } from '@cutover/react-ui'
import { RunbookEdit, RunbookSubmitData } from 'main/components/shared/runbook-edit/runbook-edit'
import { useRightPanelTypeState } from 'main/components/layout/right-panel'
import { getRunbookEdit } from 'main/services/queries/use-runbook'
import {
  useAccount,
  useAccountCustomFieldGroups,
  useAccountCustomFieldGroupsLookup,
  useAccountCustomFieldLookup,
  useAccountCustomFieldUsers,
  useAccountMetaProperty,
  useRunbookEditUpdatedRunbook,
  useRunbookPermission,
  useRunbookPermittedResource,
  useRunbookRunbookType
} from 'main/recoil/data-access'
import { getPermittedResources } from 'main/services/queries/use-permitted-resources'
import { useLanguage } from 'main/services/hooks'
import { useUpdateRunbook } from 'main/services/queries/use-update-runbook'
import { RunbookEditRunbook } from 'main/services/queries/types'
import { useProcessRunbookUpdateResponse } from 'main/recoil/data-access/updaters__TEMPORARY/runbook-operations'

export const RunbookEditPanelTaskList = memo(() => {
  const [{ runbookId }, { closeRightPanel }] = useRightPanelTypeState('runbook-edit')

  return runbookId ? <RunbookEditPanelTaskListInner runbookId={runbookId} onClose={closeRightPanel} /> : null
})

const RunbookEditPanelTaskListInner = memo(({ runbookId, onClose }: { runbookId: number; onClose: () => void }) => {
  const [isSubmitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | string[] | null>(null)
  const notify = useNotify()
  const { t } = useLanguage('runbook', { keyPrefix: 'editPanel' })

  const { mutateAsync: updateRunbook } = useUpdateRunbook()
  const processRunbookUpdateResponse = useProcessRunbookUpdateResponse()
  const canUpdate = useRunbookPermission({ attribute: 'update' })
  const [isLoading, setIsLoading] = useState(true)
  const [updatedRunbook, setUpdatedRunbook] = useRunbookEditUpdatedRunbook()
  const [permittedProjectsData, setPermittedProjectsData] = useRunbookPermittedResource()
  const account = useAccount()
  const runbookType = useRunbookRunbookType()
  const customFieldsLookup = useAccountCustomFieldLookup()
  const customFieldUsers = useAccountCustomFieldUsers()
  const customFieldGroupsLookup = useAccountCustomFieldGroupsLookup()
  const customFieldGroups = useAccountCustomFieldGroups()
  const runbookTypes = useAccountMetaProperty({ attribute: 'runbook_types' })

  const customFieldProps = {
    customFieldsLookup: customFieldsLookup.fields,
    customFieldUsers,
    customFieldGroupsLookup,
    customFieldGroups
  }

  useEffect(() => {
    const initData = async () => {
      const [runbookEditRes, permittedResourcesRes] = await Promise.all([
        getRunbookEdit(runbookId),
        getPermittedResources({ resource: 'runbook', accountId: account.id })
      ])

      setUpdatedRunbook(runbookEditRes.runbook)
      setPermittedProjectsData(permittedResourcesRes)
      setIsLoading(false)
    }

    initData()
  }, [])

  const handleSubmit = async ({ data, payload, timezone }: RunbookSubmitData) => {
    setSubmitting(true)
    updateRunbook(
      {
        ...data,
        runbook: payload,
        timezone
      },
      {
        onSuccess: response => {
          setUpdatedRunbook(response.runbook as RunbookEditRunbook)
          processRunbookUpdateResponse(response)
          notify.success(t('updateSuccess'))
          setSubmitting(false)
        },
        onError: response => {
          if (response.errors) {
            setErrorMessage(response.errors)
          }
          notify.error(t('updateError'))
          setSubmitting(false)
        }
      }
    )
  }

  return (
    updatedRunbook &&
    permittedProjectsData && (
      <RunbookEdit
        runbook={updatedRunbook}
        runbookType={runbookType}
        runbookTypes={runbookTypes}
        isLoading={isLoading}
        permittedProjectsData={permittedProjectsData}
        onClose={onClose}
        isRunbookPage
        readOnly={!canUpdate}
        customFieldProps={customFieldProps}
        account={account}
        isSubmitting={isSubmitting}
        apiErrorMessage={errorMessage}
        onSubmit={handleSubmit}
      />
    )
  )
})
