import { useHotkeys } from 'react-hotkeys-hook'
import { useRecoilCallback } from 'recoil'

import {
  useCanInitiateBulkEditActionsCallback,
  useCanInitiateBulkProgressionActionsCallback,
  useSetModalActiveState,
  useSetSelectedIds,
  useTaskProgression
} from 'main/recoil/data-access'

/** @hotkey `meta+a` */
export const useTaskListHotkeys = () => {
  const { selectedIdsSelectAll, selectedIdsRemoveAll, selectedIdsValueCallback } = useSetSelectedIds()
  const { onSkipTasks } = useTaskProgression()
  const { modalOpen } = useSetModalActiveState()
  const canInitiateBulkEditActions = useCanInitiateBulkEditActionsCallback()
  const canInitiateBulkProgressionActions = useCanInitiateBulkProgressionActionsCallback()

  useHotkeys('meta+a', selectedIdsSelectAll, { preventDefault: true }, [])
  useHotkeys('meta+d', selectedIdsRemoveAll, { preventDefault: true }, [])

  useHotkeys(
    'meta+k',
    useRecoilCallback(() => async () => {
      if (!canInitiateBulkProgressionActions()) return
      const selectedTasks = await selectedIdsValueCallback()
      if (selectedTasks.length > 0) onSkipTasks()
    }),
    { preventDefault: true },
    []
  )

  useHotkeys(
    'Backspace',
    useRecoilCallback(() => async () => {
      if (!canInitiateBulkEditActions()) return
      const selectedTasks = await selectedIdsValueCallback()
      if (selectedTasks.length > 0) modalOpen({ type: 'tasks-delete', id: selectedTasks })
    }),
    { preventDefault: true },
    []
  )
}
