import { ReactNode } from 'react'
import { useRecoilCallback } from 'recoil'
import { produce } from 'immer'
import styled from 'styled-components'

import { Avatar, DependencyType, MenuListItemProps, TaskItemIcon, TaskTypeIcon } from '@cutover/react-ui'
import {
  accountTaskTypeLookup,
  runbookState,
  runbookVersionState,
  runbookViewState_INTERNAL,
  RunbookViewStateType,
  streamsLookupState,
  streamsPermittedState,
  taskCreateFromPredecessorIdState_INTERNAL,
  taskListLookupState,
  teamsStateLookup,
  usersLookupState
} from 'main/recoil/runbook'
import { filterSelector } from 'main/recoil/shared/filters'
import { useLanguage } from 'main/services/hooks'
import {
  INTEGRATION_FINISHED_STATUSES,
  INTEGRATION_RUNNING_STATUSES,
  IntegrationActionItem,
  IntegrationFinishedStatus,
  IntegrationRunningStatus,
  IntegrationStatus,
  TaskListTask,
  TaskType
} from 'main/services/queries/types'
import { useToggleRightPanel } from 'main/components/layout/right-panel'
import { stageIconName, taskTypeIcon } from './task-list-item-props'
import { taskEditTaskTypesState } from 'main/recoil/runbook/models/tasks/task-edit'
import { fireIntegration } from 'main/services/queries/use-task'
import {
  useCanAddSnippetAfter,
  useCanCreateLinkedTaskAfter,
  useCanCreateTaskAfter,
  useCanDeleteTask,
  useCanStartTaskWhenStartable,
  useIntegrationRequestValue
} from 'main/recoil/data-access'

/* ---------------------------- Task options menu --------------------------- */

export const useBuildTaskActionMenuItems = () => {
  const { t } = useLanguage('runbook', { keyPrefix: 'taskListItem' })
  const [integrationRequest, setIntegrationRequest] = useIntegrationRequestValue()
  const getCanCreateTaskAfter = useCanCreateTaskAfter()
  const getCanCreateLinkedTaskAfter = useCanCreateLinkedTaskAfter()
  const getCanAddSnippetAfter = useCanAddSnippetAfter()
  const getCanDeleteTask = useCanDeleteTask()
  const getCanStartTaskWhenStartable = useCanStartTaskWhenStartable()

  return useRecoilCallback(
    ({ snapshot, set }) =>
      async ({
        task,
        integrationActionItem,
        integrationOptions
      }: {
        task: TaskListTask
        integrationActionItem?: IntegrationActionItem
        integrationOptions?: { [x: string]: {} | undefined }
      }) => {
        const { id, internal_id: internalId, predecessor_ids: predecessorIds } = task
        const runbookViewState = await snapshot.getPromise(runbookViewState_INTERNAL)
        const runbook = await snapshot.getPromise(runbookState)
        const runbookVersion = await snapshot.getPromise(runbookVersionState)

        const lastIntegrationEvent =
          task.integration_events.length > 0 && task.integration_events[task.integration_events.length - 1]
        const eventStatus: IntegrationStatus = lastIntegrationEvent.status?.toLowerCase()
        const canRefireIntegration =
          INTEGRATION_FINISHED_STATUSES.includes(eventStatus as IntegrationFinishedStatus) &&
          !integrationRequest.hasOwnProperty(task.id)
        const canAbortIntegration =
          INTEGRATION_RUNNING_STATUSES.includes(eventStatus as IntegrationRunningStatus) &&
          integrationOptions?.cancellable &&
          !integrationRequest.hasOwnProperty(task.id)

        const menuItems = [
          getCanStartTaskWhenStartable(id) &&
            canAbortIntegration &&
            ({
              label: t('actions.abortIntegration'),
              a11yTitle: t('actions.abortIntegration'),
              icon: 'cancel',
              appendDivider: true,
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                set(
                  runbookViewState_INTERNAL,
                  mutateModalState({
                    taskId: task.id,
                    name: integrationActionItem?.name ?? '',
                    type: 'integration-abort'
                  })
                )
              }
            } as MenuListItemProps),
          getCanStartTaskWhenStartable(id) &&
            canRefireIntegration &&
            ({
              label: t('actions.refireIntegration'),
              a11yTitle: t('actions.refireIntegration'),
              icon: 'refresh',
              appendDivider: true,
              onClick: async e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                setIntegrationRequest({ taskId: id, type: 'refire' })
                await fireIntegration({ runbookId: runbook.id, runbookVersionId: runbookVersion.id, taskId: id })
              }
            } as MenuListItemProps),
          getCanCreateTaskAfter(id) &&
            ({
              label: t('actions.addTaskAfter'),
              a11yTitle: t('actions.addTaskAfter'),
              icon: 'add',
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                set(taskCreateFromPredecessorIdState_INTERNAL, id)
              }
            } as MenuListItemProps),
          getCanCreateLinkedTaskAfter(id) &&
            ({
              label: t('actions.addLinkedTask'),
              a11yTitle: t('actions.addLinkedTask'),
              icon: 'runbook',
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                set(runbookViewState_INTERNAL, mutateModalState({ id, type: 'linked-runbook-add' }))
              }
            } as MenuListItemProps),
          getCanAddSnippetAfter(id) &&
            ({
              label: t('actions.addSnippet'),
              a11yTitle: t('actions.addSnippet'),
              icon: 'snippet',
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                set(runbookViewState_INTERNAL, mutateModalState({ id, type: 'snippet-add' }))
              }
            } as MenuListItemProps),
          {
            label: t('actions.showCriticalPath'),
            a11yTitle: t('actions.showCriticalPath'),
            icon: 'critical-path',
            disabled: !predecessorIds.length, // Note: disabling instead of omitting so no chance of completely empty menu
            onClick: e => {
              e.syntheticEvent.stopPropagation()
              e.syntheticEvent.preventDefault()
              set(filterSelector({ attribute: 'critical_to_here' }), internalId)
            }
          } as MenuListItemProps,
          {
            label: t('actions.showAncestors'),
            a11yTitle: t('actions.showAncestors'),
            icon: 'predecessors',
            disabled: !predecessorIds.length,
            onClick: e => {
              e.syntheticEvent.stopPropagation()
              e.syntheticEvent.preventDefault()
              set(filterSelector({ attribute: 'predecessors_to_here' }), internalId)
            }
          } as MenuListItemProps,
          getCanDeleteTask(id) &&
            ({
              label: t('actions.delete'),
              a11yTitle: t('actions.delete'),
              icon: 'delete',
              destructive: true,
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                set(runbookViewState_INTERNAL, mutateModalState({ id: [id], type: 'tasks-delete' }))
              }
            } as MenuListItemProps)
        ].filter(Boolean) as MenuListItemProps[]

        const mutateModalState = (modalState: RunbookViewStateType['modal']['active']) =>
          produce(runbookViewState, draft => {
            draft.modal.active = modalState
          })

        return menuItems
      }
  )
}

/* ------------------------- Task dependencies menu ------------------------- */

export const useBuildTaskDependencyMenuItems = () => {
  const toggleTaskEdit = useToggleRightPanel('task-edit')

  return useRecoilCallback(({ snapshot }) => async ({ task, type }: { task: TaskListTask; type: DependencyType }) => {
    const taskLookup = await snapshot.getPromise(taskListLookupState)
    const taskTypeLookup = await snapshot.getPromise(accountTaskTypeLookup)
    const streamLookup = await snapshot.getPromise(streamsLookupState)

    const dependencyIds = type === 'predecessors' ? task.predecessor_ids : task.successor_ids
    const tasks = dependencyIds.map(id => taskLookup[id])

    const menuItems = tasks
      .sort((a, b) => {
        return a.internal_id - b.internal_id
      })
      .map(task => {
        const { internal_id: internalId, name, id } = task
        const taskType = taskTypeLookup[task.task_type_id]

        const iconProps = {
          color: streamLookup[task.stream_id].color,
          icon: taskTypeIcon(taskType.icon, task.stage),
          inProgress: task.stage === 'in-progress',
          isOpaque: task.stage === 'complete',
          stageIcon: stageIconName({
            completionType: task.completion_type,
            stage: task.stage,
            startFixed: task.start_fixed
          })
        }

        const item = {
          icon: <TaskItemIcon iconSize="xsmall" {...iconProps} />,
          label: `#${internalId} ${name}`,
          onClick: () => toggleTaskEdit({ taskId: id })
        }
        return item
      })
    return menuItems
  })
}

/* ------------------------- Task create input menus ------------------------ */

export type TaskShortcutMenuItem = {
  id: number
  label: string
  icon?: ReactNode | string
  props?: any
}

export const useBuildTaskTypeMenuItems = () => {
  return useRecoilCallback(({ snapshot }) => async () => {
    const { taskTypes, taskTypeIntegrations } = await snapshot.getPromise(taskEditTaskTypesState)
    const taskTypesOptions =
      taskTypes?.map((t: TaskType) => ({ id: t.id, label: t.name, icon: <TaskTypeIcon icon={t.icon} /> })) ?? []
    const taskTypesIntegrationsOptions =
      taskTypeIntegrations
        ?.filter((t: TaskType) => {
          return (
            !t.name.includes('Integrations::Apps::MountPoint') &&
            t.integration_action_items[0].on.includes('PubSub::Task::Started')
          )
        })
        .map((t: TaskType) => ({
          id: t.id,
          label: t.integration_action_items[0].name,
          icon: (
            <IntegrationIcon
              alt={`${t.integration_action_items[0].name}-icon`}
              src={
                t.integration_action_items[0].image_url || t.integration_action_items[0].integration_setting.image_url
              }
            />
          )
        })) ?? []

    return [...taskTypesOptions, ...taskTypesIntegrationsOptions]
  })
}

const IntegrationIcon = styled.img`
  height: 22px;
  width: 22px;
`

export const useBuildUsersAndTeamsMenuItems = () => {
  return useRecoilCallback(({ snapshot }) => async () => {
    const teamsLookup = await snapshot.getPromise(teamsStateLookup)
    const usersLookup = await snapshot.getPromise(usersLookupState)

    const teams = Object.values(teamsLookup).map(team => {
      const { id, name, linked, color } = team
      const avatarSubject = { id, name, linked, color }
      return {
        id,
        label: name,
        icon: <Avatar subject={avatarSubject} size="small" />,
        props: { team, avatarSubject }
      }
    })

    const users = Object.values(usersLookup).map(user => {
      const { id, first_name: firstName, last_name: lastName, name, online, color } = user
      const avatarSubject = { id, first_name: firstName, last_name: lastName, name, online, color }
      return {
        id,
        label: name,
        icon: <Avatar subject={avatarSubject} size="small" />,
        props: { avatarSubject }
      }
    })

    return [...teams, ...users]
  })
}

export const useBuildStreamsMenuItems = () => {
  return useRecoilCallback(({ snapshot }) => async () => {
    const streamsLookup = await snapshot.getPromise(streamsPermittedState)

    const streams = streamsLookup.map(stream => {
      const { id, name } = stream
      return {
        id,
        label: name,
        props: { stream }
      }
    })

    return streams
  })
}
