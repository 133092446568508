import { DragEvent, useCallback, useEffect, useRef, useState } from 'react'
import {
  Loadable,
  RecoilValueReadOnly,
  SetterOrUpdater,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useResetRecoilState,
  useSetRecoilState
} from 'recoil'
import { produce } from 'immer'
import { extend, isEqual, mapValues, pullAllWith } from 'lodash'

import { useNotify } from '@cutover/react-ui'
import { currentUserIdState, currentUserState } from '../current-user'
import { CurrentUser } from 'main/services/queries/use-get-validate-token'
import {
  Account,
  CustomField,
  Favorite,
  FrontendUserSettingType,
  Run,
  RunbookEditRunbook,
  RunbookShowRunbook,
  RunbookTeam,
  RunbookVersion,
  TaskListTask,
  TaskType
} from 'main/services/queries/types'
import {
  accountCustomFieldGroupsState,
  accountCustomFieldsState,
  accountCustomFieldUsers,
  accountGlobalTaskTypeDefaultState,
  accountMetaState,
  accountResponseState_INTERNAL,
  accountState,
  accountTaskTypeLookup,
  accountTaskTypes,
  customFieldGroupsLookup,
  customFieldLookup,
  customFieldLookups,
  favoriteStateById,
  filteredTaskListDataState,
  getAccountTaskType,
  IntegrationRequest,
  IntegrationRequestType,
  isStreamPermittedState,
  isVersionCurrentState,
  isVersionEditable,
  ModalActiveType,
  newTaskStreamState,
  runbookCommentsPermissions,
  RunbookCommentsPermissionsResponse,
  runbookCurrentVersionState,
  runbookIdState,
  runbookPermission,
  RunbookPermissionsResponse,
  runbookPermissionsState,
  runbookPermittedResource,
  runbookResponseState_INTERNAL,
  runbookRunbookTypeState,
  runbookState,
  RunbookTeamsPermissionsResponse,
  runbookVersionIdState,
  runbookVersionMetaState,
  runbookVersionPermission,
  RunbookVersionPermissionsResponse,
  runbookVersionResponseState_INTERNAL,
  runbookVersionStageState,
  runbookVersionState,
  runbookViewState_INTERNAL,
  RunbookViewStateType,
  runsPermission,
  RunsPermissionsResponse,
  streamsLookupState,
  streamsPermission,
  StreamsPermissionsResponse,
  streamsPermittedState,
  streamsState,
  streamState,
  taskCreateFromPredecessorIdState_INTERNAL,
  taskListInternalIdLookupState,
  taskListLookupState,
  taskListResponseState_INTERNAL,
  taskListState,
  taskListTaskState,
  TaskProgressionState,
  taskProgressionState,
  tasksDeleteIdsAtom,
  tasksDeleteRequestState,
  tasksPermission,
  TasksPermissionsResponse,
  teamsStateLookup,
  usersLookupState,
  usersState,
  usersTeamsPermissions
} from '../runbook'
import { AccountResponseMeta } from 'main/services/api/data-providers/account/use-get-account-data'
import { useToggleFavorite } from 'main/services/queries/use-favorites'
import {
  newCommentsCount_INTERNAL,
  runbookCommentsResponseState_INTERNAL,
  taskCommentsLookupState
} from '../runbook/models/runbook/runbook-comments'
import { runbookEditUpdatedRunbook } from '../runbook/models/runbook/runbook-edit'
import { RunbookPermittedResource } from 'main/services/queries/use-permitted-resources'
import { defaultSavedFilterState, savedFilterState } from '../runbook/models/runbook/saved-filters'
import {
  RunbookCommentCreateResponse,
  RunbookResponse,
  RunbookStreamShowResponse,
  RunbookTaskBulkDeleteResponse,
  RunbookTaskBulkSkipResponse,
  RunbookTaskFinishResponse,
  RunbookTaskStartResponse,
  RunbookTaskUpdateResponse,
  SavedFilter
} from 'main/services/api/data-providers/runbook-types'
import { runPropertyState, runState } from '../runbook/models/runbook-version/run'
import { runbookComponentsSnippets } from '../runbook/models/runbook-version/runbook-components'
import { getRunbookVersion, RunbookVersionMeta, RunbookVersionUser } from 'main/services/queries/use-runbook-versions'
import { getRunbookId, getRunbookVersionId } from '../shared/nav-utils'
import { taskEditTaskTypesState } from '../runbook/models/tasks/task-edit'
import {
  finishTask,
  quickUpdateTask,
  QuickUpdateTaskPayload,
  startTask,
  TaskFinishPayload,
  TaskGetTaskResponse,
  TaskStartPayload
} from 'main/services/queries/use-task'
import { getTasks } from 'main/services/queries/use-tasks'
import { GlobalConfigType, useLanguage } from 'main/services/hooks'
import {
  useProcessTaskBulkDeleteResponse,
  useProcessTaskFinishResponse,
  useProcessTaskStartResponse
} from './updaters__TEMPORARY/task-operations'
import { getServerErrorMessages } from 'main/services/api'
import {
  activeTasksFilterState,
  ancestorsFilterState,
  appliedFilterState,
  assignedFilterState,
  completionTypeFilterState,
  criticalPathFilterState,
  criticalPathToHereFilterState,
  customFieldFilterSelector,
  dateFromFilterState,
  dateToFilterState,
  dateWithinFilterState,
  endRequirementsFilterState,
  filterSelector,
  fixedEndFilterState,
  fixedStartFilterState,
  hasCommentsFilterState,
  hasErrorsFilterState,
  hasPredecessorsFilterState,
  hasSuccessorsFilterState,
  lateFilterState,
  milestoneFilterState,
  myTasksFilterState,
  nowDateAtom,
  overRunningFilterState,
  runbookComponentFilterState,
  runbookLevelFilterState,
  runbookTeamFilterState,
  runbookTeamIncludeUsersState,
  runbookUserFilterState,
  searchAtom,
  searchQueryFilterState,
  stageFilterState,
  startNotificationFilterState,
  startRequirementsFilterState,
  streamFilterState,
  taskTypeFilterState
} from '../shared/filters'
import { RunbookFilterType } from 'main/services/tasks/filtering'
import { globalConfigProperty } from '../shared/global-config'
import { useProcessRunbookCommentsGetResponse } from './updaters__TEMPORARY/runbook-comments-operations'
import { getRunbookComments } from 'main/services/queries/use-runbook-comments'
import { RunbookComment } from 'main/services/api/data-providers/runbook-types/runbook-shared-types'

/* -------------------------------------------------------------------------- */
/*                                Global Config                               */
/* -------------------------------------------------------------------------- */

const DEFAULT_TASK_LEVEL_ID = 'level_3'
export const useGlobalTaskLevelOrDefault = (id = DEFAULT_TASK_LEVEL_ID) => {
  const taskLevels = useRecoilValue(globalConfigProperty({ attribute: 'taskLevels' })) as GlobalConfigType['taskLevels']
  // eslint-disable-next-line
  return taskLevels.find(level => level.id === id)! // task level always exists
}

export const useGlobalConfigProperty = ({ attribute }: { attribute: keyof GlobalConfigType }) =>
  useRecoilValue(globalConfigProperty({ attribute }))

/* -------------------------------------------------------------------------- */
/*                                Current User                                */
/* -------------------------------------------------------------------------- */

export const useCurrentUser = () => {
  return useRecoilValue(currentUserState) as CurrentUser // if using this hook can assume we're authenticated
}

export const useUpdateFrontendUserSettingState = () => {
  const updateSettings = useSetRecoilState(currentUserState)
  return ({ payload }: { payload: FrontendUserSettingType['data'] }) =>
    updateSettings(prevUser => {
      if (prevUser) {
        const newSettings = { ...prevUser?.frontend_user_setting, data: payload }
        return { ...prevUser, frontend_user_setting: { ...newSettings } }
      } else {
        return null
      }
    })
}

export const useCurrentUserId = () => {
  return useRecoilValue(currentUserIdState)
}

/* -------------------------------------------------------------------------- */
/*                                 Permissions                                */
/* -------------------------------------------------------------------------- */

export const useRunbookPermission = <TKey extends keyof RunbookPermissionsResponse>({
  attribute
}: {
  attribute: TKey
}) => useRecoilValue(runbookPermission({ attribute }))

export const useRunbookVersionPermission = <TKey extends keyof RunbookVersionPermissionsResponse>({
  attribute
}: {
  attribute: TKey
}) => useRecoilValue(runbookVersionPermission({ attribute }))

export const useRunbookCommentsPermission = <TKey extends keyof RunbookCommentsPermissionsResponse>({
  attribute
}: {
  attribute: TKey
}) => useRecoilValue(runbookCommentsPermissions({ attribute }))

export const useTasksPermission = <TKey extends keyof TasksPermissionsResponse>({ attribute }: { attribute: TKey }) =>
  useRecoilValue(tasksPermission({ attribute }))

export const useRunsPermission = <TKey extends keyof RunsPermissionsResponse>({ attribute }: { attribute: TKey }) =>
  useRecoilValue(runsPermission({ attribute }))

export const useStreamsPermission = <TKey extends keyof StreamsPermissionsResponse>({
  attribute
}: {
  attribute: TKey
}) => useRecoilValue(streamsPermission({ attribute }))

export type TaskShowPermissions = ReturnType<typeof getTaskShowPermissions>
export type StreamShowPermissions = ReturnType<typeof getStreamShowPermissions>

export const useGetTaskShowPermissions = () => {
  const { id: currentUserId } = useCurrentUser()

  return useCallback((resp: TaskGetTaskResponse) => getTaskShowPermissions(resp, currentUserId), [currentUserId])
}

const getTaskShowPermissions = (resp: TaskGetTaskResponse, currentUserId: number) => {
  return mapValues(resp.meta.permissions, v => v?.includes(currentUserId))
}

export const useGetStreamShowPermissions = () => {
  const { id: currentUserId } = useCurrentUser()

  return useCallback(
    (resp: RunbookStreamShowResponse) => getStreamShowPermissions(resp, currentUserId),
    [currentUserId]
  )
}

const getStreamShowPermissions = (resp: RunbookStreamShowResponse, currentUserId: number) => {
  return mapValues(resp.meta.permissions, v => v?.includes(currentUserId))
}

export const useRunbookTeamsPermission = <TKey extends keyof RunbookTeamsPermissionsResponse>({
  attribute
}: {
  attribute: TKey
}) => {
  return useRecoilValue(usersTeamsPermissions({ attribute }))
}

/* -------------------------------------------------------------------------- */
/*                                   Account                                  */
/* -------------------------------------------------------------------------- */

// Use this hook in components to avoid re-renders when non-dependent properties change
export const useAccountProperty = <TKey extends keyof Account>({ attribute }: { attribute: TKey }) =>
  useRecoilValue(accountState)[attribute]

// Use this hook in components to avoid re-renders when non-dependent properties change
export const useAccountMetaProperty = <TKey extends keyof AccountResponseMeta>({ attribute }: { attribute: TKey }) =>
  useRecoilValue(accountMetaState)[attribute]

export const useAccountId = () => {
  return useAccountProperty({ attribute: 'id' })
}

export const useAccount = () => {
  return useRecoilValue(accountState)
}

/* -------------------------------------------------------------------------- */
/*                                Custom Fields                               */
/* -------------------------------------------------------------------------- */

export const useCustomField = (id: number) => {
  const lookup = useRecoilValue(customFieldLookup)

  return lookup[id]
}

export const useAccountCustomFieldLookup = () => {
  return useRecoilValue(customFieldLookups)
}

export const useAccountCustomFieldUsers = () => {
  return useRecoilValue(accountCustomFieldUsers)
}

export const useAccountCustomFieldGroupsLookup = () => {
  return useRecoilValue(customFieldGroupsLookup)
}

export const useAccountCustomFieldGroups = () => {
  return useRecoilValue(accountCustomFieldGroupsState)
}

export const useAccountCustomFields = () => {
  return useRecoilValue(accountCustomFieldsState)
}

/* -------------------------------------------------------------------------- */
/*                                 Task Types                                 */
/* -------------------------------------------------------------------------- */

export const useTaskTypeOrDefault = (id?: number | null) => {
  // @ts-ignore fine here
  const taskType = useRecoilValue(getAccountTaskType(id))
  const defaultTaskType = useRecoilValue(accountGlobalTaskTypeDefaultState)

  return taskType ?? defaultTaskType
}

export const useAccountTaskTypes = () => {
  return useRecoilValue(accountTaskTypes)
}

/* -------------------------------------------------------------------------- */
/*                                  Favorites                                 */
/* -------------------------------------------------------------------------- */

export const useToggleRecoilFavorites = ({
  id
}: {
  id: number
}): [Favorite | undefined, (payload: Favorite) => void] => {
  const favorite = useRecoilValue(favoriteStateById(id))

  const mutation = useToggleFavorite()

  const toggleFavorite = (payload: Favorite) => {
    mutation.mutate(payload)
  }

  return [favorite, toggleFavorite]
}

/* -------------------------------------------------------------------------- */
/*                                  Comments                                  */
/* -------------------------------------------------------------------------- */

type CommentsStateReturn = {
  isLoading: boolean
  comments?: RunbookComment[]
}

/**
 * Will load the comments to populate the data store the first time something that depends on this data renders.
 * Always load the full set of comments to the store and we filter featured comments in the frontend when appropriate
 * instead of hitting the endpoint separately--in order for websocket updates to sync data we need a single
 * source of truth.
 */
export const useCommentsState = (taskId?: number): CommentsStateReturn => {
  const commentsResponse = useRecoilValue(runbookCommentsResponseState_INTERNAL)
  const [isLoading, setIsLoading] = useState(commentsResponse === null)
  const runbookVersionId = useRunbookVersionId()
  const runbookId = useRunbookId()
  const processRunbookCommentsGetResponse = useProcessRunbookCommentsGetResponse()
  const runbookComments = commentsResponse?.comments
  const taskCommentsLookup = useRecoilValue(taskCommentsLookupState)

  useEffect(() => {
    const initData = async () => {
      setIsLoading(true)

      try {
        const response = await getRunbookComments({ runbookId, runbookVersionId })
        processRunbookCommentsGetResponse(response)
      } finally {
        setIsLoading(false)
      }
    }

    if (commentsResponse === null) initData()
  }, [])

  const comments = taskId ? taskCommentsLookup[taskId] : runbookComments

  return {
    isLoading,
    comments
  }
}

export const useResetRunbookComments = () => {
  return useResetRecoilState(runbookCommentsResponseState_INTERNAL)
}

export const useFeaturedComments = () => {
  const { comments } = useCommentsState()
  return comments?.filter(comment => comment.featured) ?? []
}

export const useTaskCommentsCount = (id: number) => {
  const taskComments = useRecoilValue(taskCommentsLookupState)
  return (taskComments[id] ?? []).length
}

export const useNewCommentsCount = () => {
  return useRecoilValue(newCommentsCount_INTERNAL)
}

export const useResetNewCommentsCount = () => {
  return useResetRecoilState(newCommentsCount_INTERNAL)
}

/* -------------------------------------------------------------------------- */
/*                                Runbook Edit                                */
/* -------------------------------------------------------------------------- */

export const useRunbookEditUpdatedRunbook = (): [RunbookEditRunbook, (newValue: RunbookEditRunbook) => void] => {
  const [updatedRunbook, setUpdatedRunbook] = useRecoilState<RunbookEditRunbook>(runbookEditUpdatedRunbook)

  return [updatedRunbook, setUpdatedRunbook]
}

/* -------------------------------------------------------------------------- */
/*                                   Runbook                                  */
/* -------------------------------------------------------------------------- */

export const useRunbookCurrentVersion = () => useRecoilValue(runbookCurrentVersionState)
export const useRunbookCurrentVersionProperty = <TKey extends keyof RunbookVersion>({
  attribute
}: {
  attribute: TKey
}) => useRecoilValue(runbookCurrentVersionState)[attribute]

export const useRunbookProperty = <TKey extends keyof RunbookShowRunbook>({ attribute }: { attribute: TKey }) =>
  useRecoilValue(runbookState)[attribute]

export const useRunbookPermissionsState = () => useRecoilValue(runbookPermissionsState)

export const useRunbookRunbookType = () => {
  return useRecoilValue(runbookRunbookTypeState)
}

export const useRunbookId = () => {
  return useRecoilValue(runbookIdState)
}

export const useRunbook = () => {
  return useRecoilValue(runbookState)
}

export const useIsRunbookIncident = () => {
  return useRecoilValue(runbookRunbookTypeState).incident
}

export const useRunbookPermittedResource = (): [
  RunbookPermittedResource | null,
  (newValue: RunbookPermittedResource) => void
] => {
  const [permittedProjectsData, setPermittedProjectsData] = useRecoilState<RunbookPermittedResource | null>(
    runbookPermittedResource
  )

  return [permittedProjectsData, setPermittedProjectsData]
}

/* -------------------------------------------------------------------------- */
/*                                Saved Filters                               */
/* -------------------------------------------------------------------------- */

export const useDefaultSavedFilter = () => {
  return useRecoilValue(defaultSavedFilterState)
}

export const useAddSavedFilter = () =>
  useRecoilCallback(({ set, snapshot }) => async (filter: SavedFilter) => {
    const existingFilters = await snapshot.getPromise(savedFilterState)

    const updatedSavedFilters = produce(existingFilters, draft => {
      const existingFilter = draft.findIndex(f => f.id === filter.id)
      if (existingFilter >= 0) {
        draft[existingFilter] = filter
      } else {
        draft.push(filter)
      }
      return draft
    })

    set(savedFilterState, updatedSavedFilters)
  })

export const useRemoveSavedFilter = () =>
  useRecoilCallback(({ set, snapshot }) => async (id: number) => {
    const existingFilters = await snapshot.getPromise(savedFilterState)
    const updatedSavedFilters = existingFilters.filter(f => f.id !== id)

    set(savedFilterState, updatedSavedFilters)
  })

export const useToggleDefaultFilter = () =>
  useRecoilCallback(({ set, snapshot }) => async (id: number) => {
    const existingFilters = await snapshot.getPromise(savedFilterState)
    const updatedSavedFilters = produce(existingFilters, draft => {
      const existingFilterIndex = draft.findIndex(f => f.id === id)
      const existingFilter = draft[existingFilterIndex]

      if (existingFilter && existingFilter.default) {
        draft[existingFilterIndex].default = false
      } else if (existingFilter) {
        draft.forEach(f => (f.default = false))
        draft[existingFilterIndex].default = true
        draft[existingFilterIndex].global = true
      }
    })

    set(savedFilterState, updatedSavedFilters)
  })

export const useToggleGlobalFilter = () =>
  useRecoilCallback(({ set, snapshot }) => async (id: number) => {
    const existingFilters = await snapshot.getPromise(savedFilterState)
    const updatedSavedFilters = existingFilters.map(f => ({ ...f, global: f.id === id ? !f.global : f.global }))

    set(savedFilterState, updatedSavedFilters)
  })

export const useHasSavedFilters = () => {
  return useRecoilValue(savedFilterState).length > 0
}

export const sortFiltersDefaultFirst = (f: SavedFilter[]) =>
  produce(f, draft => draft.sort((a, b) => (a.default && !b.default ? -1 : 0)))

/* -------------------------------------------------------------------------- */
/*                                   Filters                                  */
/* -------------------------------------------------------------------------- */

export const useNowDate = () => {
  const setDate = useSetRecoilState(nowDateAtom)
  return {
    setNowDate: () => setDate(Math.floor(Date.now() / 1000)),
    resetNowDate: () => setDate(0)
  }
}

export const useAppliedFilters = () => {
  return useRecoilValue(appliedFilterState)
}

export function useClearFilterState<K extends keyof RunbookFilterType>(key: K) {
  return useResetRecoilState(filterSelector({ attribute: key })) as any
}

// WARNING: this clears all query params -- even if we don't consider them a "filter". this needs to be updated
// https://cutover.atlassian.net/browse/CFE-1441
export function useClearAllFilterState() {
  return useRecoilCallback(({ set, snapshot }) => async () => {
    const appliedFilters = await snapshot.getPromise(appliedFilterState)

    Object.keys(appliedFilters).forEach(key => {
      set(filterSelector({ attribute: key as keyof RunbookFilterType }), undefined)
    })

    set(searchAtom, '')
  })
}

export const useCustomFieldFilterState = (id: number) => {
  const setCustomFieldProperty = useSetRecoilState(customFieldFilterSelector({ attribute: id }))
  const value = useCustomMemoizedFieldFilterValue(id) as any

  return [value, setCustomFieldProperty] as const
}

export const useCustomFieldAppliedFiltersState = () => {
  const { f: cfFilters } = useAppliedFilters()
  return cfFilters
}

const useCustomMemoizedFieldFilterValue = (id: number) => {
  const cfFilters = useCustomFieldAppliedFiltersState()
  const cfFiter = cfFilters?.[id]
  const memoizedVal = useRef<any>(cfFiter)

  useEffect(() => {
    if (!isEqual(cfFiter, memoizedVal.current)) {
      memoizedVal.current = cfFiter
    }
  }, [cfFiter])

  return !isEqual(memoizedVal.current, cfFiter) ? cfFiter : memoizedVal.current
}

function createFilterHook<T>(key: keyof RunbookFilterType, selector: RecoilValueReadOnly<T>) {
  return () => {
    const value = useRecoilValue(selector)
    const setValue = useSetRecoilState(filterSelector({ attribute: key })) as unknown as SetterOrUpdater<T>

    return [value, setValue] as [typeof value, typeof setValue]
  }
}
export const useRunbookLevelFilter = createFilterHook('lv', runbookLevelFilterState)
export const useRunbookUserFilter = createFilterHook('user', runbookUserFilterState)
export const useRunbookTeamFilter = createFilterHook('team', runbookTeamFilterState)
export const useRunbookTeamIncludeUsersFilter = createFilterHook('includeUsers', runbookTeamIncludeUsersState)
export const useCriticalPathFilter = createFilterHook('critical', criticalPathFilterState)
export const useMilestoneFilter = createFilterHook('m', milestoneFilterState)
export const useDateWithinFilter = createFilterHook('dd', dateWithinFilterState)
export const useStartNotificationFilter = createFilterHook('sn', startNotificationFilterState)
export const useFixedStartFilter = createFilterHook('fs', fixedStartFilterState)
export const useFixedEndFilter = createFilterHook('fe', fixedEndFilterState)
export const useHasCommentsFilter = createFilterHook('c', hasCommentsFilterState)
export const useLateFilter = createFilterHook('l', lateFilterState)
export const useOverRunningFilter = createFilterHook('or', overRunningFilterState)
export const useHasPredecessorsFilter = createFilterHook('hp', hasPredecessorsFilterState)
export const useHasSuccessorsFilter = createFilterHook('hs', hasSuccessorsFilterState)
export const useHasErrorsFilter = createFilterHook('he', hasErrorsFilterState)
export const useMyTasksFilter = createFilterHook('mt', myTasksFilterState)
export const useActiveTasksFilter = createFilterHook('at', activeTasksFilterState)
export const useDateFromFilter = createFilterHook('df', dateFromFilterState)
export const useDateToFilter = createFilterHook('dt', dateToFilterState)
export const useCompletionTypeFilter = createFilterHook('ct', completionTypeFilterState)
export const useStartRequirementsFilter = createFilterHook('sr', startRequirementsFilterState)
export const useEndRequirementsFilter = createFilterHook('er', endRequirementsFilterState)
export const useAssignedFilter = createFilterHook('a', assignedFilterState)
export const useStageFilter = createFilterHook('stage', stageFilterState)
export const useStreamFilter = createFilterHook('stream', streamFilterState)
export const useSearchQueryFilter = createFilterHook('q', searchQueryFilterState)
export const useTaskTypeFilter = createFilterHook('type', taskTypeFilterState)
export const useCriticalPathToHereFilter = createFilterHook('critical_to_here', criticalPathToHereFilterState)
export const useAncestorsFilter = createFilterHook('predecessors_to_here', ancestorsFilterState)
export const useRunbookComponentFilter = createFilterHook('rbc', runbookComponentFilterState)

/* -------------------------------------------------------------------------- */
/*                                     Run                                    */
/* -------------------------------------------------------------------------- */

export const useRunProperty = <TKey extends keyof Run>(args: { attribute: TKey }) =>
  useRecoilValue(runPropertyState(args)) as Run[TKey]

export const useRun = () => useRecoilValue(runState)

/* -------------------------------------------------------------------------- */
/*                             Runbook Components                             */
/* -------------------------------------------------------------------------- */

export const useRunbookComponentsSnippets = () => {
  return useRecoilValue(runbookComponentsSnippets)
}

export const useHasSnippets = () => {
  return useRecoilValue(runbookComponentsSnippets).length > 0
}

/* -------------------------------------------------------------------------- */
/*                               Runbook Version                              */
/* -------------------------------------------------------------------------- */

export const useRunbookVersionProperty = <TKey extends keyof RunbookVersion>(args: { attribute: TKey }) =>
  useRecoilValue(runbookVersionState)[args.attribute]

export const useRunbookVersion = () => {
  return useRecoilValue(runbookVersionState)
}
export const useRunbookVersionMetaProperty = <TKey extends keyof RunbookVersionMeta>(args: { attribute: TKey }) =>
  useRecoilValue(runbookVersionMetaState)[args.attribute]

export const useRunbookVersionId = () => {
  return useRunbookVersionProperty({ attribute: 'id' })
}

export const useIsDynamicRunbook = () => {
  const { dynamic: isDynamic } = useRunbookRunbookType()
  return isDynamic
}

export const useIsEditableRunbook = () => {
  return useRecoilValue(isVersionEditable)
}

export const useIsCurrentRunbookVersion = () => {
  return useRecoilValue(isVersionCurrentState)
}

export const useRunbookVersionRefetch = () => {
  const setRunbookVersion = useSetRecoilState(runbookVersionResponseState_INTERNAL)
  const runbookId = getRunbookId() as string
  const runbookVersionId = getRunbookVersionId() as string

  return async () => {
    setRunbookVersion(await getRunbookVersion(runbookId, runbookVersionId))
  }
}

/* -------------------------------------------------------------------------- */
/*                                   Streams                                  */
/* -------------------------------------------------------------------------- */

export const usePermittedStreams = () => {
  return useRecoilValue(streamsPermittedState)
}

export const useStream = (streamId: number) => {
  return useRecoilValue(streamState({ id: streamId }))
}

export const useStreams = () => {
  return useRecoilValue(streamsState)
}

export const useStreamLoadable = (streamId: number) => {
  return useRecoilValueLoadable(streamState({ id: streamId }))
}

export const useStreamParent = (streamId: number) => {
  const stream = useRecoilValue(streamState({ id: streamId }))
  // have to get the lookup in full because we can't call hooks conditionally just when the parent_id exists
  const lookup = useRecoilValue(streamsLookupState)
  return stream.parent_id ? lookup[stream.parent_id] : undefined
}

/* -------------------------------------------------------------------------- */
/*                                 Task Create                                */
/* -------------------------------------------------------------------------- */

export const useToggleTaskCreateState = () => {
  const [fromPredecessorId, setFromPredecessorId] = useRecoilState(taskCreateFromPredecessorIdState_INTERNAL)

  const toggleFromPredecessorId = ({ fromPredecessorId }: { fromPredecessorId?: number }) =>
    setFromPredecessorId(prev => (prev === fromPredecessorId ? undefined : fromPredecessorId))

  return [fromPredecessorId, toggleFromPredecessorId] as [number | undefined, typeof toggleFromPredecessorId]
}

export const useSetTaskCreateState = () => {
  const setFromPredecessorId = useSetRecoilState(taskCreateFromPredecessorIdState_INTERNAL)

  const setFromPredecessor = ({ fromPredecessorId }: { fromPredecessorId?: number }) =>
    setFromPredecessorId(fromPredecessorId)

  return setFromPredecessor
}

/* -------------------------------------------------------------------------- */
/*                                  Task Edit                                 */
/* -------------------------------------------------------------------------- */

export const useTaskEditTaskTypes = () => {
  return useRecoilValue(taskEditTaskTypesState)
}

export const useTaskTypeRemovedName = (taskTypeId: number) => {
  const taskTypeLookup = useRecoilValue(accountTaskTypeLookup)
  const currentTaskType = taskTypeLookup[taskTypeId]
  const taskTypeName = currentTaskType.integration_action_items[0]?.name || currentTaskType.name

  return currentTaskType.archived ? `${taskTypeName} [Archived]` : undefined
}

/* -------------------------------------------------------------------------- */
/*                                    Tasks                                   */
/* -------------------------------------------------------------------------- */

export const useGetCanCreateTask = () => {
  return useRecoilCallback(({ snapshot }) => (prevTaskStreamId?: number) => {
    const newTaskStreamId = snapshot.getLoadable(newTaskStreamState({ prevTaskStreamId })).getValue()
    const isTaskStreamPermitted = snapshot.getLoadable(isStreamPermittedState({ streamId: newTaskStreamId })).getValue()
    if (!isTaskStreamPermitted) return false
    const { stage: runStage } = snapshot.getLoadable(runbookVersionState).getValue()
    const { dynamic: isDynamic } = snapshot.getLoadable(runbookRunbookTypeState).getValue()
    return !!newTaskStreamId && (['planning', 'paused'].includes(runStage) || (runStage !== 'complete' && isDynamic))
  })
}

export const useCanCreateRootTask = () => {
  const canCreateTask = useGetCanCreateTask()
  return canCreateTask()
}

export const useNewTaskStreamId = ({ prevTaskStreamId }: { prevTaskStreamId?: number } = {}) => {
  return useRecoilValue(newTaskStreamState({ prevTaskStreamId }))
}

export const useCanInitiateBulkEditActions = () => {
  const versionIsEditable = useRecoilValue(isVersionEditable)
  const hasUpdatePermissions = useRunbookPermission({ attribute: 'update' })
  const hasPermittedStreams = !!usePermittedStreams().length

  return (hasUpdatePermissions || hasPermittedStreams) && versionIsEditable
}

export const useCanInitiateBulkEditActionsCallback = () =>
  useRecoilCallback(({ snapshot }) => async () => {
    const versionIsEditable = await snapshot.getPromise(isVersionEditable)
    const hasUpdatePermissions = await snapshot.getPromise(runbookPermission({ attribute: 'update' }))
    const hasPermittedStreams = !!(await snapshot.getPromise(streamsPermittedState)).length

    return (hasUpdatePermissions || hasPermittedStreams) && versionIsEditable
  })

export const useCanInitiateBulkProgressionActions = () => {
  const hasUpdatePermissions = useRunbookPermission({ attribute: 'update' })
  const stage = useRecoilValue(runbookVersionStageState)
  return hasUpdatePermissions && stage === 'active'
}

export const useCanInitiateBulkProgressionActionsCallback = () =>
  useRecoilCallback(({ snapshot }) => async () => {
    const hasUpdatePermissions = await snapshot.getPromise(runbookPermission({ attribute: 'update' }))
    const stage = await snapshot.getPromise(runbookVersionStageState)

    return hasUpdatePermissions && stage === 'active'
  })

/* -------------------------------------------------------------------------- */
/*                                  Task List                                 */
/* -------------------------------------------------------------------------- */
export const useTaskListInternalIdLookupState = () => {
  return useRecoilValue(taskListInternalIdLookupState)
}

export const useTaskListTaskProperty = <TKey extends keyof TaskListTask>({
  id,
  attribute
}: {
  id: number
  attribute: TKey
}) => {
  return useRecoilValue(taskListTaskState(id))[attribute]
}

export const useTaskListTaskTeams = (taskId: number) => {
  const lookup = useRecoilValue(teamsStateLookup)
  const runbook_team_ids = useTaskListTaskProperty({ id: taskId, attribute: 'runbook_team_ids' })

  return runbook_team_ids?.map(id => lookup[id])
}

export const useTaskListTaskUsers = (taskId: number) => {
  const lookup = useRecoilValue(usersLookupState)
  const user_ids = useTaskListTaskProperty({ id: taskId, attribute: 'user_ids' })
  return user_ids?.map(id => lookup[id])
}

export const useFilteredTaskListIds = () => {
  const [ids] = useRecoilValue(filteredTaskListDataState)
  return ids
}

export const useCriticalPathTaskIds = () => {
  const [, criticalPathIds] = useRecoilValue(filteredTaskListDataState)
  return criticalPathIds
}

export const useTaskListTask = (id: number) => {
  return useRecoilValue(taskListTaskState(id))
}

export const useTaskList = () => {
  return useRecoilValue(taskListState)
}

export const useTaskListTask_TaskType = (taskId: number) => {
  const task_type_id = useTaskListTaskProperty({ id: taskId, attribute: 'task_type_id' })

  return useRecoilValue(getAccountTaskType(task_type_id))
}

export const useTaskListTask_TaskTypeLoadable = (taskId: number) => {
  const task_type_id = useTaskListTaskProperty({ id: taskId, attribute: 'task_type_id' })

  return useRecoilValueLoadable(getAccountTaskType(task_type_id))
}

export const useTaskListRefetch = () => {
  const resetTaskList = useResetRecoilState(taskListResponseState_INTERNAL)
  const setTaskList = useSetRecoilState(taskListResponseState_INTERNAL)
  const getRunbookId = useRecoilCallback(({ snapshot }) => async () => {
    return await snapshot.getPromise(runbookIdState)
  })
  const getRunbookVersionId = useRecoilCallback(({ snapshot }) => async () => {
    return await snapshot.getPromise(runbookVersionIdState)
  })

  return async () => {
    resetTaskList() // necessary to show loading state of list
    setTaskList(await getTasks(await getRunbookId(), await getRunbookVersionId()))
  }
}

export const useHasStartedSuccessors = () =>
  useRecoilCallback(({ snapshot }) => (taskId: number) => {
    const task = snapshot.getLoadable(taskListTaskState(taskId)).getValue()
    const allowedAddBelowStages = ['default', 'startable']
    for (const sId of task.successor_ids) {
      const successorTask = snapshot.getLoadable(taskListTaskState(sId)).getValue()
      if (!allowedAddBelowStages.includes(successorTask.stage)) return true
    }

    return false
  })

export const useIsTaskAdmin = () =>
  useRecoilCallback(({ snapshot }) => () => {
    return snapshot.getLoadable(runbookPermission({ attribute: 'update' })).getValue()
  })

export const useTaskUserIds = () =>
  useRecoilCallback(({ snapshot }) => (taskId: number) => {
    const task = snapshot.getLoadable(taskListTaskState(taskId)).getValue()
    const teamLookup = snapshot.getLoadable(teamsStateLookup).getValue()

    return task.runbook_team_ids.flatMap(teamId => teamLookup[teamId]?.user_ids ?? []).concat(task.user_ids)
  })

export const useCanStartTaskWhenStartable = () => {
  const getIsTaskAdmin = useIsTaskAdmin()
  const getTaskUserIds = useTaskUserIds()
  return useRecoilCallback(({ snapshot }) => (taskId: number) => {
    const task = snapshot.getLoadable(taskListTaskState(taskId)).getValue()
    const isAdmin = getIsTaskAdmin()

    // base requirements
    if (!snapshot.getLoadable(isVersionCurrentState).getValue()) return false
    const { loadingIds } = snapshot.getLoadable(runbookViewState_INTERNAL).getValue()
    if (loadingIds[taskId]) return false

    if (isAdmin) return true

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const currentUserId = snapshot.getLoadable(currentUserIdState).getValue()!
    const userIds = getTaskUserIds(taskId)
    if (!userIds.includes(currentUserId)) return false

    if (task.start_requirements === 'any_can_start') return true
    if (!task.started_user_ids?.includes(currentUserId)) return true

    return false
  })
}

export const useCanTaskStart = () => {
  const canTaskStartWhenStartable = useCanStartTaskWhenStartable()

  return useRecoilCallback(({ snapshot }) => (taskId: number) => {
    const task = snapshot.getLoadable(taskListTaskState(taskId)).getValue()
    if (!canTaskStartWhenStartable(taskId)) return false
    return task.stage === 'startable'
  })
}

export const useCanTaskFinish = () => {
  const getIsTaskAdmin = useIsTaskAdmin()
  const getTaskUserIds = useTaskUserIds()

  return useRecoilCallback(({ snapshot }) => (taskId: number) => {
    const task = snapshot.getLoadable(taskListTaskState(taskId)).getValue()

    // base requirements
    if (task.stage !== 'in-progress') return false
    if (!snapshot.getLoadable(isVersionCurrentState).getValue()) return false
    const { loadingIds } = snapshot.getLoadable(runbookViewState_INTERNAL).getValue()
    if (loadingIds[taskId]) return false

    if (!!task.linked_resource?.id) return false

    if (getIsTaskAdmin()) return true

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const currentUserId = snapshot.getLoadable(currentUserIdState).getValue()!
    const userIds = getTaskUserIds(taskId)
    if (!!currentUserId && !userIds.includes(currentUserId)) return false

    if (task.end_requirements === 'any_can_end') return true
    if (task.end_requirements === 'all_must_end' && !task.ended_user_ids?.includes(currentUserId)) return true
    if (task.end_requirements === 'same_must_end' && task.started_user_ids?.includes(currentUserId)) return true

    return false
  })
}

export const useCanCreateTaskAfterWithoutSuccessorCheck = () => {
  return useRecoilCallback(({ snapshot }) => (taskId: number) => {
    const isRunbookEditable = snapshot.getLoadable(isVersionEditable).getValue()
    if (!isRunbookEditable) return false

    const { loadingIds } = snapshot.getLoadable(runbookViewState_INTERNAL).getValue()
    if (loadingIds[taskId]) return false

    const task = snapshot.getLoadable(taskListTaskState(taskId)).getValue()
    const isTaskStreamPermitted = snapshot.getLoadable(isStreamPermittedState({ streamId: task.stream_id })).getValue()
    if (!isTaskStreamPermitted) return false

    return true
  })
}

export const useCanCreateTaskAfter = () => {
  const getCanCreateAfterWithoutSuccessorCheck = useCanCreateTaskAfterWithoutSuccessorCheck()
  const getTaskHasStartedSuccessors = useHasStartedSuccessors()

  return useRecoilCallback(() => (taskId: number) => {
    const createCreateIfNoStartedSuccessors = getCanCreateAfterWithoutSuccessorCheck(taskId)
    if (!createCreateIfNoStartedSuccessors) return false

    const hasStartedSuccessorsCheck = getTaskHasStartedSuccessors(taskId)
    if (hasStartedSuccessorsCheck) return false

    return true
  })
}

export const useCanDisplayCreateAfterFromIcon = () => {
  const getCanCreateAfterWithoutSuccessorCheck = useCanCreateTaskAfterWithoutSuccessorCheck()

  return useRecoilCallback(({ snapshot }) => (taskId: number) => {
    if (!getCanCreateAfterWithoutSuccessorCheck(taskId)) return false

    const { dynamic: isDynamic } = snapshot.getLoadable(runbookRunbookTypeState).getValue()
    if (!isDynamic) return true

    const { stage: taskStage } = snapshot.getLoadable(taskListTaskState(taskId)).getValue()
    const { stage: runbookStage } = snapshot.getLoadable(runbookVersionState).getValue()
    return taskStage === 'default' || runbookStage === 'paused'
  })
}

export const useCanCreateLinkedTaskAfter = () => {
  const getTaskCanCreateAfter = useCanCreateTaskAfter()

  return useRecoilCallback(({ snapshot }) => (taskId: number) => {
    if (!getTaskCanCreateAfter(taskId)) return false

    const { template_type: templateType, linked_runbook_details: linkedRunbookDetails } = snapshot
      .getLoadable(runbookState)
      .getValue()

    const isSnippet = templateType === 'snippet'
    const isChildRunbook = Object.keys(linkedRunbookDetails || {}).length !== 0

    return !isChildRunbook && !isSnippet
  })
}

export const useCanAddSnippetAfter = () => {
  const getHasStartedSuccessors = useHasStartedSuccessors()

  return useRecoilCallback(({ snapshot }) => (taskId: number) => {
    const canAddSnippet = snapshot.getLoadable(tasksPermission({ attribute: 'add_snippet' })).getValue()
    if (!canAddSnippet) return false

    const { template_type: templateType } = snapshot.getLoadable(runbookState).getValue()
    if (templateType === 'snippet') return false

    if (!snapshot.getLoadable(isVersionEditable).getValue()) return false
    if (getHasStartedSuccessors(taskId)) return false

    return true
  })
}

export const useCanDeleteTask = () => {
  return useRecoilCallback(({ snapshot }) => (taskId: number) => {
    const isRunbookEditable = snapshot.getLoadable(isVersionEditable).getValue()
    if (!isRunbookEditable) return false

    const task = snapshot.getLoadable(taskListTaskState(taskId)).getValue()
    const isTaskStreamPermitted = snapshot.getLoadable(isStreamPermittedState({ streamId: task.stream_id })).getValue()
    if (!isTaskStreamPermitted) return false

    return task.stage === 'default' || task.stage === 'startable'
  })
}

export const useCanUpdateTask = useCanDeleteTask

export const useCanProgressTask = () => {
  const getCanTaskStart = useCanTaskStart()
  const getCanTaskFinish = useCanTaskFinish()

  return useRecoilCallback(({ snapshot }) => (taskId: number) => {
    const { loadingIds } = snapshot.getLoadable(runbookViewState_INTERNAL).getValue()
    if (loadingIds[taskId]) return false

    const { run } = snapshot.getLoadable(runbookVersionState).getValue()
    const isActiveRun = run?.mode === 'active'
    if (!isActiveRun) return false

    const task = snapshot.getLoadable(taskListTaskState(taskId)).getValue()

    if (task.stage === 'complete') return false
    if (task.stage === 'default') return false
    if (task.stage === 'startable' && !getCanTaskStart(taskId)) return false
    if (task.stage === 'in-progress' && !getCanTaskFinish(taskId)) return false
    if (task.errors?.length) return false

    return true
  })
}

/* -------------------------------------------------------------------------- */
/*                              Task Progression                              */
/* -------------------------------------------------------------------------- */

export const useTaskProgression = () => {
  // :!: warning: do NOT listen to any state within this function :!:
  const { modalOpen, modalClose, modalHistoryValueCallback } = useSetModalActiveState()
  const { t } = useLanguage('tasks')
  const { loadingIdAdd, loadingIdRemove } = useSetLoadingIdsState()
  const processTaskStartResponse = useProcessTaskStartResponse()
  const processTaskFinishResponse = useProcessTaskFinishResponse()
  const { taskStartNotification, taskFinishNotification } = useTaskNotifications()
  const notify = useNotify()

  const onSkipTasks = useRecoilCallback(({ snapshot }) => async (ids?: number[]) => {
    const tasks = await snapshot.getPromise(taskListLookupState)
    const selectedIds = (await snapshot.getPromise(runbookViewState_INTERNAL)).selectedIds
    const skipIds = ids || selectedIds

    const error = skipIds.find(id => {
      const task = tasks[id]
      return task.stage === 'complete' || task.completion_type === 'complete_skipped'
    })

    if (error) {
      notify.warning(t('list.unableToSkipWarning.message'), { title: t('list.unableToSkipWarning.title') })
    } else {
      modalOpen({ type: 'tasks-skip', id: skipIds })
    }
  })

  const startOrFinishTask = useRecoilCallback(
    ({ snapshot }) =>
      async (id: number, payload: Partial<TaskStartPayload | TaskFinishPayload> = {}) => {
        const progressionState = await snapshot.getPromise(taskProgressionState(id))
        const modalHistory = await modalHistoryValueCallback()
        const runbookId = await snapshot.getPromise(runbookIdState)
        const runbookVersionId = await snapshot.getPromise(runbookVersionIdState)
        const override = !!modalHistory.find(modal => {
          if (modal.type === 'task-override-fixed-start') return true
          if (modal.type === 'task-override' && !progressionState?.optional) return true
          return modal.type === 'task-override' && (modal.context as { override: boolean } | undefined)?.override
        })

        modalClose() // Important: don't move this above anything that uses the modalHistory otherwise it will clear it

        try {
          loadingIdAdd(id)
          const startable = progressionState?.stage === 'startable'
          const request = startable ? startTask : finishTask
          const response = await request({
            runbookId,
            runbookVersionId,
            taskId: id,
            payload: {
              override,
              field_values_attributes: [],
              selected_successor_ids: [],
              ...payload
            }
          })
          if (response) {
            if (startable) {
              processTaskStartResponse(response as RunbookTaskStartResponse)
              taskStartNotification(response as RunbookTaskStartResponse)
            } else {
              processTaskFinishResponse(response as RunbookTaskFinishResponse)
              taskFinishNotification(response as RunbookTaskFinishResponse)
            }
          }
          loadingIdRemove(id)
        } catch (e: any) {
          const errorMessage = getServerErrorMessages(
            e,
            t(
              progressionState?.stage === 'startable'
                ? 'taskActionModal.errorStartMessage'
                : 'taskActionModal.errorFinishMessage'
            )
          )[0]
          loadingIdRemove(id)
          notify.error(errorMessage, {
            title: t(
              progressionState?.stage === 'startable'
                ? 'taskActionModal.errorStartTitle'
                : 'taskActionModal.errorFinishTitle'
            )
          })
        }
      },
    []
  )

  const resolveProgressionModalCallback = useCallback(
    ({
      accountName,
      customFields,
      task,
      taskType,
      progressionState,
      from,
      currentUser,
      runbook
    }: {
      task: TaskListTask
      taskType: TaskType
      progressionState: TaskProgressionState
      accountName: Account['name']
      customFields: CustomField[]
      currentUser: CurrentUser
      runbook: RunbookShowRunbook
      from?: ModalActiveType
    }): ModalActiveType | undefined => {
      if (!progressionState) return

      if ((!from || !from.type || !from.type.includes('task-override')) && progressionState.override)
        return {
          type: progressionState.override === 'fixed-start' ? 'task-override-fixed-start' : 'task-override',
          id: task.id
        }

      if (progressionState.stage === 'startable') {
        const hasCfs = customFields.find(
          ({ archived, apply_to, account_name, constraint }) =>
            !archived &&
            apply_to.slug === 'task_start' &&
            (account_name === accountName || account_name === 'Global') &&
            (!constraint || (constraint?.task_type_id || []).includes(task.task_type_id))
        )

        const shouldShowDescription = task.has_description && runbook.settings_task_description_on_task_start

        return shouldShowDescription ||
          hasCfs ||
          taskType.key === 'snippet' ||
          (taskType.auto_finish && taskType.conditional_progression)
          ? { type: 'task-action', id: task.id }
          : undefined
      }

      if (progressionState.stage === 'finishable') {
        const hasCfs = customFields.find(
          cf =>
            !cf.archived &&
            cf.apply_to.slug === 'task_end' &&
            (cf.account_name === accountName || cf.account_name === 'Global') &&
            (!cf.constraint || (cf.constraint?.task_type_id || []).includes(task.task_type_id))
        )

        if (
          !hasCfs &&
          !taskType.conditional_progression &&
          !from &&
          !currentUser?.frontend_user_setting?.data?.task_finish_confirm_hidden?.includes(runbook.id)
        )
          return { type: 'task-finish-confirm', id: task.id }

        return hasCfs || taskType.conditional_progression ? { type: 'task-action', id: task.id } : undefined
      }
    },
    []
  )

  const resolveProgressionModalRecoilCallback = useRecoilCallback(
    ({ snapshot }) =>
      async (id: number, { from }: { from?: ModalActiveType } = {}): Promise<ModalActiveType | undefined> => {
        const progressionState = await snapshot.getPromise(taskProgressionState(id))
        const {
          account,
          meta: { custom_fields }
        } = await snapshot.getPromise(accountResponseState_INTERNAL)
        const task = await snapshot.getPromise(taskListTaskState(id))
        const taskType = await snapshot.getPromise(getAccountTaskType(task.task_type_id))
        const currentUser = (await snapshot.getPromise(currentUserState)) as CurrentUser
        const runbook = await snapshot.getPromise(runbookState)

        const customFields = custom_fields?.map(cf => ({
          ...cf,
          constraint: cf.constraint ? JSON.parse(cf.constraint) : null
        }))

        return resolveProgressionModalCallback({
          task,
          taskType,
          progressionState,
          accountName: account.name,
          customFields,
          currentUser,
          runbook,
          from
        })
      }
  )

  return { onSkipTasks, resolveProgressionModalCallback, resolveProgressionModalRecoilCallback, startOrFinishTask }
}

export const useTaskProgressionState = (id: number) => {
  return useRecoilValue(taskProgressionState(id))
}

export const useIsTaskActionModalTask = (id: number) => {
  const activeModal = useModalActiveValue()
  return activeModal?.type === 'task-action' ? id === activeModal.id : undefined
}

/* -------------------------------------------------------------------------- */
/*                                Tasks Delete                                */
/* -------------------------------------------------------------------------- */

export const useTaskDeleteTasksState: () => [
  Loadable<RunbookTaskBulkDeleteResponse | undefined>,
  SetterOrUpdater<number[] | undefined>
] = () => {
  const processBulkDeleteResponse = useProcessTaskBulkDeleteResponse()
  const deleteTasksRequestState = useRecoilValueLoadable(tasksDeleteRequestState)
  const setTasksToDelete = useSetRecoilState(tasksDeleteIdsAtom)
  const { state, contents } = deleteTasksRequestState

  useEffect(() => {
    if (state === 'hasValue' && contents) {
      processBulkDeleteResponse(contents)
      setTasksToDelete(undefined)
    }
  }, [state, contents])

  return [deleteTasksRequestState, setTasksToDelete]
}

/* -------------------------------------------------------------------------- */
/*                                Teams / Users                               */
/* -------------------------------------------------------------------------- */

type DragUserTeamData = {
  type: 'runbook_team' | 'user'
  id: number
}

export const useDropAssignment = ({
  canUpdateTask,
  teams,
  users
}: {
  canUpdateTask: boolean
  teams: RunbookTeam[]
  users: RunbookVersionUser[]
}) => {
  const [isLoadingAvatar, setIsLoadingAvatar] = useState(false)

  const isDropPermitted = (e: DragEvent) => {
    if (!e.dataTransfer || !e.dataTransfer.getData('text')) {
      return false
    }

    if (!canUpdateTask) {
      return false
    }

    const transferData = JSON.parse(e.dataTransfer.getData('text'))

    // Check if transfer is redundant (users/teams already exist)
    if (
      (transferData.type === 'runbook_team' && !!teams.find(team => team.id === transferData.id)) ||
      (transferData.type === 'user' && !!users.find(user => user.id === transferData.id))
    ) {
      return false
    }

    return true
  }

  const handleDropAssign = useRecoilCallback(
    ({ snapshot, set }) =>
      async ({ taskId, data }: { taskId: number; data: DragUserTeamData }) => {
        const runbookId = await snapshot.getPromise(runbookIdState)
        const runbookVersionId = await snapshot.getPromise(runbookVersionIdState)
        const task = await snapshot.getPromise(taskListTaskState(taskId))

        setIsLoadingAvatar(true)

        const payload = {} as QuickUpdateTaskPayload
        if (data.type === 'runbook_team') {
          payload.runbook_teams = [Number(data.id), ...task.runbook_team_ids]
        } else if (data.type === 'user') {
          payload.users = [Number(data.id), ...task.user_ids]
        }

        const response = await quickUpdateTask({ runbookId, runbookVersionId, taskId, payload })

        set(taskListResponseState_INTERNAL, prevTasksResponse =>
          produce(prevTasksResponse, draftTasksResponse => {
            const task = draftTasksResponse.tasks.find(t => t.id === taskId)
            if (response && task) {
              task.user_ids = response.task.user_ids
              task.runbook_team_ids = response.task.runbook_team_ids
            }
          })
        )

        setIsLoadingAvatar(false)
      }
  )

  return {
    handleDropAssign,
    isLoadingAvatar,
    isDropPermitted
  }
}

export const useRunbookVersionUsers = () => {
  return useRecoilValue(usersState)
}

/* -------------------------------------------------------------------------- */
/*                                    View                                    */
/* -------------------------------------------------------------------------- */

/* ---------------------------- View:Selected Ids --------------------------- */
export const useSelectedIdsValue = () => {
  const { selectedIds } = useRecoilValue(runbookViewState_INTERNAL)
  return selectedIds
}
export const useSetSelectedIds = () => {
  // :!: warning: this function is for setters and callbacks only. Do NOT listen to any state here :!:
  const selectedIdsValueCallback = useRecoilCallback(({ snapshot }) => async () => {
    return (await snapshot.getPromise(runbookViewState_INTERNAL)).selectedIds
  })

  const selectedIdAdd = useRecoilCallback(({ set }) => (id: number) => {
    set(runbookViewState_INTERNAL, previousState =>
      produce(previousState, draft => {
        draft.selectedIds.push(id)
      })
    )
  })
  const selectedIdRemove = useRecoilCallback(({ set }) => (id: number) => {
    set(runbookViewState_INTERNAL, previousState =>
      produce(previousState, draft => {
        draft.selectedIds = draft.selectedIds.filter(draftId => draftId !== id)
      })
    )
  })
  const selectedIdsRemove = useRecoilCallback(({ set }) => (ids: number[]) => {
    set(runbookViewState_INTERNAL, prev =>
      produce(prev, draft => {
        pullAllWith(draft.selectedIds, ids)
      })
    )
  })
  const selectedIdToggle = useRecoilCallback(({ snapshot }) => async (id: number, shiftKey: boolean) => {
    const selectedIds = (await snapshot.getPromise(runbookViewState_INTERNAL)).selectedIds
    if (shiftKey && selectedIds.length === 1 && selectedIds[0] !== id) {
      // If holding shift and clicking a different task to the already selected one, select all tasks between the 2
      const [taskIds] = await snapshot.getPromise(filteredTaskListDataState)
      const indexOfSelected = taskIds.indexOf(selectedIds[0])
      const indexOfClicked = taskIds.indexOf(id)
      const startIndex = Math.min(indexOfSelected, indexOfClicked)
      const endIndex = Math.max(indexOfSelected, indexOfClicked)
      const newSelectedIds = taskIds.slice(startIndex, endIndex + 1)
      selectedIdsOverwrite(newSelectedIds)
    } else {
      selectedIds.includes(id) ? selectedIdRemove(id) : selectedIdAdd(id)
    }
  })
  const selectedIdsOverwrite = useRecoilCallback(({ set }) => (ids: number[]) => {
    set(runbookViewState_INTERNAL, previousState =>
      produce(previousState, draft => {
        draft.selectedIds = ids
      })
    )
  })
  const selectedIdsSelectAll = useRecoilCallback(({ snapshot }) => async () => {
    const [taskIds] = await snapshot.getPromise(filteredTaskListDataState)
    selectedIdsOverwrite(taskIds)
  })

  const selectedIdsRemoveAll = () => selectedIdsOverwrite([])

  const selectedIdsToggleAll = useRecoilCallback(({ snapshot }) => async () => {
    const selectedIds = (await snapshot.getPromise(runbookViewState_INTERNAL)).selectedIds
    selectedIds.length ? selectedIdsRemoveAll() : selectedIdsSelectAll()
  })

  return {
    selectedIdAdd,
    selectedIdRemove,
    selectedIdsRemove,
    selectedIdToggle,
    selectedIdsOverwrite,
    selectedIdsSelectAll,
    selectedIdsRemoveAll,
    selectedIdsToggleAll,
    selectedIdsValueCallback
  }
}

/* ------------------------------- View:Modal ------------------------------- */
export const useModalActiveValue = () => useRecoilValue(runbookViewState_INTERNAL).modal.active
export const useModalHistoryValue = <TModalHistory extends object = {}>() =>
  (useRecoilValue(runbookViewState_INTERNAL) as RunbookViewStateType<TModalHistory>).modal.history
export const useSetModalActiveState = () => {
  // :!: warning: this function is for setters and callbacks only. Do NOT listen to any state here :!:
  const setViewState = useSetRecoilState(runbookViewState_INTERNAL)

  const modalActiveValueCallback = useRecoilCallback(({ snapshot }) => async () => {
    return (await snapshot.getPromise(runbookViewState_INTERNAL)).modal.active
  })
  const modalHistoryValueCallback = useRecoilCallback(({ snapshot }) => async () => {
    return (await snapshot.getPromise(runbookViewState_INTERNAL)).modal.history
  })

  const modalClose = () =>
    setViewState(previousState =>
      produce(previousState, draft => {
        draft.modal.active = undefined
        draft.modal.history = []
      })
    )
  const modalOpen = (modal: ModalActiveType) =>
    setViewState(previousState =>
      produce(previousState, draft => {
        draft.modal.active = modal
      })
    )
  const modalContinue = (nextModal: ModalActiveType, previousModal: ModalActiveType & { context?: object }) =>
    setViewState(previousState =>
      produce(previousState, draft => {
        draft.modal.active = nextModal
        draft.modal.history.push(previousModal)
      })
    )

  return { modalClose, modalOpen, modalContinue, modalActiveValueCallback, modalHistoryValueCallback }
}

/* -------------------------------- View:Menu ------------------------------- */
export const useMenu = () => {
  const { taskList } = useRecoilValue(runbookViewState_INTERNAL)
  return taskList
}

export const useSetMenu = () =>
  useRecoilCallback(({ set }) => (menu: RunbookViewStateType['taskList']['menu']) => {
    set(runbookViewState_INTERNAL, previousState =>
      produce(previousState, draft => {
        extend(draft.taskList.menu, menu)
      })
    )
  })

export const useClearMenu = () =>
  useRecoilCallback(({ set }) => () => {
    set(runbookViewState_INTERNAL, previousState =>
      produce(previousState, draft => {
        draft.taskList.isMenuOpen = false
        draft.taskList.menu.taskId = undefined
        draft.taskList.menu.type = undefined
        draft.taskList.menu.triggerRef = undefined
        draft.taskList.menu.keyPrefix = undefined
        draft.taskList.menu.items = []
        draft.taskList.menu.minWidth = undefined
        draft.taskList.menu.maxWidth = undefined
        draft.taskList.menu.maxHeight = undefined
      })
    )
  })

export const useSetMenuOpenState = () =>
  useRecoilCallback(({ set }) => (isOpen: boolean) => {
    set(runbookViewState_INTERNAL, previousState =>
      produce(previousState, draft => {
        draft.taskList.isMenuOpen = isOpen
      })
    )
  })

/* ---------------------------- View:Integrations --------------------------- */
export const useIntegrationRequest = () => {
  const { taskList } = useRecoilValue(runbookViewState_INTERNAL)
  return taskList.integrationRequest
}

export const useSetIntegrationRequest = () => {
  const setViewState = useSetRecoilState(runbookViewState_INTERNAL)

  return (request: IntegrationRequest) =>
    setViewState(previousState =>
      produce(previousState, draft => {
        const { taskId, type } = request
        draft.taskList.integrationRequest[taskId] = type
      })
    )
}

export const useRemoveIntegrationRequest = () => {
  const setViewState = useSetRecoilState(runbookViewState_INTERNAL)

  return (taskId: number) =>
    setViewState(previousState =>
      produce(previousState, draft => {
        delete draft.taskList.integrationRequest[taskId]
      })
    )
}

export const useIntegrationRequestValue = (): [
  { [x: number]: IntegrationRequestType },
  (request: IntegrationRequest) => void
] => {
  const integrationRequest = useIntegrationRequest()
  const setIntegrationRequest = useSetIntegrationRequest()

  return [integrationRequest, setIntegrationRequest]
}

/* ---------------------------- View:Loading Ids ---------------------------- */

export const useLoadingIdsAllValue = () => useRecoilValue(runbookViewState_INTERNAL).loadingIds

export const useLoadingIdValue = (id: number) => useRecoilValue(runbookViewState_INTERNAL).loadingIds[id]

export const useSetLoadingIdsState = () => {
  // :!: : this function is for setters and callbacks only. Do NOT listen to any state here :!:
  const setViewState = useSetRecoilState(runbookViewState_INTERNAL)

  const loadingIdsValueCallback = useRecoilCallback(({ snapshot }) => async () => {
    return (await snapshot.getPromise(runbookViewState_INTERNAL)).loadingIds
  })

  const loadingIdAdd = (id: number) =>
    setViewState(previousState =>
      produce(previousState, draft => {
        draft.loadingIds[id] = true
      })
    )
  const loadingIdRemove = (id: number) =>
    setViewState(previousState =>
      produce(previousState, draft => {
        delete draft.loadingIds[id]
      })
    )
  const loadingIdAddBulk = (ids: number[]) =>
    setViewState(previousState =>
      produce(previousState, draft => {
        ids.forEach(id => (draft.loadingIds[id] = true))
      })
    )
  const loadingIdRemoveBulk = (ids: number[]) =>
    setViewState(previousState =>
      produce(previousState, draft => {
        ids.forEach(id => delete draft.loadingIds[id])
      })
    )
  return { loadingIdAdd, loadingIdRemove, loadingIdAddBulk, loadingIdRemoveBulk, loadingIdsValueCallback }
}

/* --------------------------- View:Request State --------------------------- */

/** @description returns the combined state of all requests necessary for loading the runbook. Using this hook will make
 * requests to these endpoints if they have not been called further up in the hierarchy. It's extremely important not to use
 * this hook above the RunbookLoadingBoundary (so called at the time of writing), and even more important not to use this
 * above the runbook channel subscriber. The reason for this is because you will miss socket messages and the runbook data will
 * come out of sync (at best) or the entire page will error if a websocket message is missed and a subsequent update makes
 * reference to that missed data (eg. missing a 'create task' update, then that task being referenced in meta.changed_tasks) */
export const useRunbookRequestsValue_DANGEROUS = () => {
  const { state: accountResponse } = useRecoilValueLoadable(accountResponseState_INTERNAL)
  const { state: taskResponse } = useRecoilValueLoadable(taskListResponseState_INTERNAL)
  const { state: rbResponse } = useRecoilValueLoadable(runbookResponseState_INTERNAL)
  const { state: rbvResponse } = useRecoilValueLoadable(runbookVersionResponseState_INTERNAL)

  const allResponseState = [accountResponse, taskResponse, rbResponse, rbvResponse]
  const isLoading = allResponseState.includes('loading')
  const isError = allResponseState.includes('hasError')
  const isSuccess = allResponseState.includes('hasValue')

  return { isLoading, isError, isSuccess }
}

export const useRunbookRequestsState = () => {
  /** @description returns the combined state of all requests necessary for loading the runbook. Using this hook will make
   * requests to these endpoints if they have not been called further up in the hierarchy. It's extremely important not to use
   * this hook above the RunbookLoadingBoundary (so called at the time of writing), and even more important not to use this
   * above the runbook channel subscriber. The reason for this is because you will miss socket messages and the runbook data will
   * come out of sync (at best) or the entire page will error if a websocket message is missed and a subsequent update makes
   * reference to that missed data (eg. missing a 'create task' update, then that task being referenced in meta.changed_tasks) */
  const runbookRequestsValueCallback_DANGEROUS = useRecoilCallback(({ snapshot }) => () => {
    const { state: accountResponse } = snapshot.getLoadable(accountResponseState_INTERNAL)
    const { state: taskResponse } = snapshot.getLoadable(taskListResponseState_INTERNAL)
    const { state: rbResponse } = snapshot.getLoadable(runbookResponseState_INTERNAL)
    const { state: rbvResponse } = snapshot.getLoadable(runbookVersionResponseState_INTERNAL)

    const allResponseState = [accountResponse, taskResponse, rbResponse, rbvResponse]
    const isLoading = allResponseState.includes('loading')
    const isError = allResponseState.includes('hasError')
    const isSuccess = allResponseState.includes('hasValue')

    return { isLoading, isError, isSuccess }
  })

  return { runbookRequestsValueCallback_DANGEROUS }
}

/* -------------------------------------------------------------------------- */
/*                                Notifications                               */
/* -------------------------------------------------------------------------- */

export const useCommentNotifications = () => {
  const { t } = useLanguage('notification')
  const notify = useNotify()
  const { id: currentUserId } = useCurrentUser()

  const commentCreateNotification = useCallback(
    (response: RunbookCommentCreateResponse) => {
      if (response.meta.headers.request_user_id === currentUserId) return

      let message
      const userName = response.meta.headers.request_user_name
      if (response.comment.task_id) {
        message = t('comment.created.userMessageTask', { userName, taskName: response.comment.task?.name })
      } else {
        message = t('comment.created.userMessageRunbook', { userName })
      }
      notify.success(message, { title: t('comment.created.title') })
    },
    [currentUserId]
  )

  return {
    commentCreateNotification
  }
}

export const useTaskNotifications = () => {
  const notify = useNotify()
  const { id: currentUserId } = useCurrentUser()
  const { t } = useLanguage('notification')

  const taskStartOrFinishNotification = useCallback(
    (response: RunbookTaskStartResponse | RunbookTaskFinishResponse, messageKey: string) => {
      let message
      const taskName = response.task.name
      if (response.meta.headers.request_user_id === currentUserId) {
        message = t(`task.${messageKey}.currentUserMessage`, { taskName })
      } else {
        const userName = response.meta.headers.request_user_name
        message = t(`task.${messageKey}.userMessage`, { userName, taskName })
      }
      notify.success(message, { title: t(`task.${messageKey}.title`) })
    },
    [currentUserId]
  )

  const taskStartNotification = useCallback(
    (response: RunbookTaskStartResponse) => {
      const messageKey = response.task.stage === 'startable' ? 'startable' : 'started'
      taskStartOrFinishNotification(response, messageKey)
    },
    [taskStartOrFinishNotification]
  )

  const taskFinishNotification = useCallback(
    (response: RunbookTaskFinishResponse) => {
      const messageKey = response.task.stage === 'in-progress' ? 'finishable' : 'finished'
      taskStartOrFinishNotification(response, messageKey)
    },
    [taskStartOrFinishNotification]
  )

  const taskSkippedNotification = useCallback(
    (response: RunbookTaskBulkSkipResponse) => {
      let message
      if (response.meta.headers.request_user_id === currentUserId) {
        message = t('task.skipped.currentUserMessage')
      } else {
        const userName = response.meta.headers.request_user_name
        message = t('task.skipped.userMessage', { userName })
      }
      notify.success(message, { title: t('task.skipped.title') })
    },
    [currentUserId]
  )

  const taskUpdateNotification = useCallback(
    (response: RunbookTaskUpdateResponse) => {
      let message
      if (response.meta.headers.request_user_id === currentUserId) {
        message = t('task.updated.currentUserMessage', { taskName: response.task.name })
      } else {
        const userName = response.meta.headers.request_user_name
        message = t('task.updated.userMessage', { userName: userName, taskName: response.task.name })
      }
      notify.success(message, { title: t('task.updated.title') })
    },
    [currentUserId]
  )

  const notifyTaskAction = useCallback((response: RunbookResponse) => {
    switch (response.meta.headers.request_method) {
      case 'start':
        return taskStartNotification(response as RunbookTaskStartResponse)
      case 'finish':
        return taskFinishNotification(response as RunbookTaskFinishResponse)
      case 'bulk_skip':
        return taskSkippedNotification(response as RunbookTaskBulkSkipResponse)
      case 'update':
        return taskUpdateNotification(response as RunbookTaskUpdateResponse)
      default:
        return () => {}
    }
  }, [])

  return {
    taskStartNotification,
    taskFinishNotification,
    taskSkippedNotification,
    taskUpdateNotification,
    notifyTaskAction
  }
}
