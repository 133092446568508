import { useRecoilCallback, useRecoilValue } from 'recoil'
import { isEqual } from 'lodash'
import { useEffectOnce, useUnmount } from 'react-use'
import { eventManager } from 'event-manager'

import {
  ActiveRightPanelState,
  ActiveRightPanelTypeState,
  CloseRightPanelAction,
  EmptyRightPanelTypeDefinition,
  OpenRightPanelAction,
  OpenRightPanelTypeProps,
  RightPanel,
  RightPanelType,
  RightPanelTypeDefinition,
  ToggleRightPanelAction
} from './right-panel-registry'
import { toggleRightPanelLayout } from '../layout-hooks'
import { runbookAtom } from 'main/recoil/shared/recoil-state-runbook-decorators'

// Notes on hook naming:
// "...RightPanelType..." the "type" language here means the hook is scoped to a specitfic right panel `type`, e.g., 'task-edit'. These
// can return objects (or functions that take objects) with a known shape because `task-edit` locks in `taskId`, but 'runbook-edit' locks
// `runbookId`. "runbookId" would only populate with an id when it is referencing the runbook id specific to the scoped panel, even when
// another panel has registered with the same data structure might be active. Since these will always return an object of a known shape,
// the "active" language is omitted from the hook names.

const EMPTY_PANEL = {} as RightPanel

/* ---------------------------------- Store --------------------------------- */

export const activeRightPanel_INTERNAL = runbookAtom<RightPanel | null>({
  key: 'right-panel',
  default: null
})

/* ----------------------------- Accessing store ---------------------------- */

function useRightPanelHookFunctions_INTERNAL<T extends RightPanelType | undefined = undefined>(panelType?: T) {
  const openRightPanel = useRecoilCallback(({ set }) => args => {
    if (args.type && !panelType) {
      set(activeRightPanel_INTERNAL, args)
    } else if (panelType) {
      set(activeRightPanel_INTERNAL, { ...args, type: panelType })
    }

    toggleRightPanelLayout(true)
  }) as OpenRightPanelAction<T>

  const closeRightPanel = useRecoilCallback(({ reset, snapshot }) => () => {
    const activePanel = snapshot.getLoadable(activeRightPanel_INTERNAL).getValue()

    if (activePanel) {
      activePanel?.onClose?.()
      reset(activeRightPanel_INTERNAL)
      toggleRightPanelLayout(false)
    }
  }) as CloseRightPanelAction

  const getActiveRightPanelCallback = useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(activeRightPanel_INTERNAL).getValue()
  )

  return {
    openRightPanel,
    closeRightPanel,
    getActiveRightPanelCallback
  }
}

/* -------------------------------------------------------------------------- */
/*                         Scoped to a specific panel                          */
/* -------------------------------------------------------------------------- */

/* -------------------------------- Get value ------------------------------- */

/**
 * @returns a right panel object for the passed panel type. This value itself will never be null or undefined
 * (why "active" is omitted from hook name). When the panel type *is* active, the value will have the data for
 * the active panel {@link RightPanelTypeDefinition<T>}, otherwise it will return the {@link EmptyRightPanelTypeDefinition} for
 * the panel type.
 */
export const useRightPanelTypeValue = <T extends RightPanelType>(panelType: T) => {
  const activePanel = useRecoilValue(activeRightPanel_INTERNAL)

  if (activePanel && activePanel.type === panelType) {
    return activePanel as RightPanelTypeDefinition<T>
  } else {
    return EMPTY_PANEL as EmptyRightPanelTypeDefinition<T>
  }
}

/* -------------------------------- Set value ------------------------------- */

export function useSetActiveRightPanelTypeState<T extends RightPanelType>(panelType: T) {
  const { openRightPanel, closeRightPanel } = useRightPanelHookFunctions_INTERNAL(panelType)
  return { closeRightPanel, openRightPanel }
}

/* --------------------------- Set value by toggle -------------------------- */

type TogglePanelFunctionType<T extends RightPanelType> = (
  ...args: Parameters<(closeArg: false) => void> | Parameters<ToggleRightPanelAction<T>>
) => void

/**
 * Provides a function to set the state of the right panel which is bound to a specific exact view of any given panel.
 * Requires passing the panel type as an argument to the hook so it can make a comparison based on the remaining panel
 * data if triggered while the same panel type is already active. You can also pass `false` to use this to force the
 * close behavior.
 *
 * @param panelType The {@link RightPanelType | type} of the panel to toggle between open and closed
 * @param defaultMatcher optional custom matcher function to compare incoming open props to that of an active panel to
 * determine if the panel should be closed instead of opened. If not provided, uses lodash's `isEqual` function. Can also
 * be passed as an option to the returned function which overrides this default.
 */
export const useToggleRightPanel = <T extends RightPanelType>(
  panelType: T,
  defaultMatcher?: (activePanel: OpenRightPanelTypeProps<T>, otherPanel: OpenRightPanelTypeProps<T>) => boolean
) => {
  const { openRightPanel, closeRightPanel } = useRightPanelHookFunctions_INTERNAL()

  return useRecoilCallback(({ snapshot }) => (openPanelTypeProps, opts = {}) => {
    if (openPanelTypeProps === false) {
      closeRightPanel()
      return
    }

    const activeRightPanel = snapshot.getLoadable(activeRightPanel_INTERNAL).getValue()
    const nextPanel = { type: panelType, ...openPanelTypeProps } as RightPanel

    if (!activeRightPanel) {
      openRightPanel(nextPanel)
      return
    }

    const { matcher = defaultMatcher || isEqual } = opts
    const { onClose: _onNewPanelClose, ...restOpenPanel } = nextPanel
    const { onClose: _onActivePanelClose, ...restActivePanel } = activeRightPanel
    const isMatchedPanelOpen =
      activeRightPanel.type === panelType && matcher(restActivePanel as any, restOpenPanel as any)

    isMatchedPanelOpen ? closeRightPanel() : openRightPanel(nextPanel)
  }) as TogglePanelFunctionType<T>
}

/* ---------------------------- Get and set value --------------------------- */

/**
 * @warning only use this state hook if you need to set the panel state (i.e., open/close) AND read from the active panel.
 * If you only have to set state, you can avoid unnecessary component updates by using {@link useToggleRightPanel} or
 * {@link useSetActiveRightPanelTypeState}.
 *
 * @returns a React `useState` style tuple. The value element will always be of the shape of the passed panel type and this is
 * the reason "active" is omitted from the hook's name.  The setter value has functions for opening the specific panel, or closing whatever is active.
 */
export const useRightPanelTypeState = <T extends RightPanelType>(panelType: T): ActiveRightPanelTypeState<T> => {
  const panelValue = useRightPanelTypeValue(panelType)
  const setPanel = useSetActiveRightPanelTypeState(panelType)
  return [panelValue, setPanel] as unknown as ActiveRightPanelTypeState<T>
}

/* -------------------------------------------------------------------------- */
/*                                 No scoping                                 */
/* -------------------------------------------------------------------------- */

/* -------------------------------- Get value ------------------------------- */

export const useActiveRightPanelValue = () => {
  return useRecoilValue(activeRightPanel_INTERNAL)
}

/**
 * A convenience hook to get the type identifier for the active right panel. If there is no active panel, returns `undefined`
 * This value does not change between different instances of the same panel type.
 */
export const useActiveRightPanelValueType = () => {
  const activeRightPanel = useActiveRightPanelValue()
  return activeRightPanel?.type
}

/* -------------------------------- Set value ------------------------------- */

/**
 * Provides generic open and close functions for the right panel. The `openRightPanel` setter takes a
 * type arg so you can use it to open different panels in the same component. To get a function which
 * actions specifically on an individual panel, often helpful in toggling, see {@link useToggleRightPanel}.
 */
export function useSetActiveRightPanelState() {
  const { openRightPanel, closeRightPanel } = useRightPanelHookFunctions_INTERNAL()
  return { closeRightPanel, openRightPanel }
}

/* ---------------------------- Get and set value --------------------------- */

/**
 * @warning only use this state hook if you need to set the panel state (i.e., open/close) AND read from the active panel.
 * If you only have to set state, you can avoid unnecessary component updates by using {@link useSetActiveRightPanelState}.
 *
 * @returns a React `useState` style tuple. The value element will only have a value if there is an active panel, and null otherwise.
 * The setter value has functions for opening any panel (param takes object with `type`), or closing whatever is active.
 */
export const useActiveRightPanelState = (): ActiveRightPanelState => {
  const activePanel = useActiveRightPanelValue()
  const { closeRightPanel, openRightPanel } = useRightPanelHookFunctions_INTERNAL()
  return [activePanel, { closeRightPanel, openRightPanel }]
}

/* ------------------------------ Get callback ------------------------------ */

/**
 * Returns a function to get the active right panel on demand. This is powerful for avoiding component re-renders by
 * allowing you to defer retrieval of the active panel until you are in a context where you need it, such as at the time
 * an event is handled. If you subscribed to the state of the active panel at the top level of your component, you would
 * in order to use that info when handling your event, you re-render when *any* panel data updated, for *all* panel types,
 * regardless of what the user is interacting with.
 *
 * @example
 * const { getActiveRightPanel } = useGetActiveRightPanelValue()
 * const taskId = props.taskId
 *
 * const handleClickItem = () => {
 *   const activePanel = getActiveRightPanel()
 *   if (activePanel.type === 'task-edit' && activePanel.taskId === taskId) {
 *     // ...
 *   }
 * }
 */
export const useGetActiveRightPanelValue = () => {
  const { getActiveRightPanelCallback } = useRightPanelHookFunctions_INTERNAL()
  return { getActiveRightPanel: getActiveRightPanelCallback }
}

/* -------------------------------------------------------------------------- */
/*                            Lifecycle management                            */
/* -------------------------------------------------------------------------- */

export const useCloseRightPanelOnMount = ({ triggerAngular }: { triggerAngular?: boolean } = {}) => {
  const { closeRightPanel } = useSetActiveRightPanelState()

  useEffectOnce(() => {
    closeRightPanel()
    if (triggerAngular) {
      eventManager.emit('close-angular-right-panel')
    }
  })
}

export const useCloseRightPanelOnUnmount = (triggerAngular?: boolean) => {
  const { closeRightPanel } = useSetActiveRightPanelState()

  useUnmount(() => {
    closeRightPanel()
    if (triggerAngular) {
      eventManager.emit('close-angular-right-panel')
    }
  })
}
