import { Ref } from 'react'

import { MenuListItemProps } from '@cutover/react-ui'
import { runbookAtom } from 'main/recoil/shared/recoil-state-runbook-decorators'
import { TaskListTask } from 'main/services/queries/types'

export type ModalActiveTaskProgressionType =
  | { id: number; type: 'task-action' }
  | { id: number; type: 'task-override' }
  | { id: number; type: 'task-override-optional' }
  | { id: number; type: 'task-override-fixed-start' }
  | { id: number; type: 'task-finish-confirm' }
  | { id: never; type: 'task-start-block' }
  | {
      id: number
      type: 'task-abandon-confirm'
      data: TaskAbandonConfirmData
    }
export type ModalActiveType =
  | ModalActiveTaskProgressionType
  | { id: number; type: 'snippet-add' }
  | { id: number; type: 'linked-runbook-add' }
  | { id: number[]; type: 'tasks-delete' }
  | { id: number[]; type: 'tasks-skip' }
  | { taskId: number; name: string; type: 'integration-abort' }

export type TaskListMenu = {
  taskId?: number
  type?: 'options' | 'predecessors' | 'successors'
  triggerRef?: Ref<HTMLElement>
  keyPrefix?: string
  isOpen?: boolean
  items: MenuListItemProps[]
  minWidth?: number
  maxWidth?: number
  maxHeight?: number
}

export type IntegrationRequestType = 'refire' | 'cancel'
export type IntegrationRequest = {
  taskId: number
  type: IntegrationRequestType
}

export type TaskAbandonConfirmData = {
  field_values?: any
  tasks: TaskListTask[]
  selected_successor_ids: number[]
}

export type RunbookViewStateType<TModalHistory extends object = {}> = {
  loadingIds: Record<number, boolean | undefined>
  selectedIds: number[]
  modal: {
    active?: ModalActiveType
    history: (ModalActiveType & { context?: TModalHistory })[]
  }
  taskList: {
    isMenuOpen: boolean
    menu: TaskListMenu
    integrationRequest: { [x: number]: IntegrationRequestType }
  }
}

export const runbookViewState_INTERNAL = runbookAtom<RunbookViewStateType>({
  key: 'runbook-view-state',
  default: {
    loadingIds: {},
    selectedIds: [],
    modal: {
      active: undefined,
      history: []
    },
    taskList: {
      isMenuOpen: false,
      menu: {
        taskId: undefined,
        type: undefined,
        items: [],
        triggerRef: undefined,
        keyPrefix: undefined,
        minWidth: undefined,
        maxWidth: undefined,
        maxHeight: undefined
      },
      integrationRequest: {}
    }
  }
})
