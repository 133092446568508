import { memo, useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { isEqual } from 'lodash'

import { Badge, MultiSelectControl } from '@cutover/react-ui'
import { useCustomField, useCustomFieldFilterState } from 'main/recoil/data-access'
import { FieldOption } from 'main/services/queries/types'
import { fieldOptionCountsState } from 'main/recoil/runbook/models/runbook-version/custom-fields/custom-fields-filters'
import { AnyOrNoneControls } from './any-or-none-fields'

export const CustomFieldMultiSelectControl = memo(({ id }: { id: number }) => {
  const customField = useCustomField(id)
  const optionsData = useRecoilValue(fieldOptionCountsState(id))
  const [value, setValue] = useCustomFieldFilterState(id)

  return (
    <MultiSelectControlInner
      a11yTitle={customField.display_name || customField.name}
      value={value}
      setValue={setValue}
      optionsData={optionsData}
    />
  )
})

type MultiSelectControlInnerProps = {
  value: any
  setValue: any
  a11yTitle?: string
  optionsData: { option: FieldOption; count: number }[]
}

const MultiSelectControlInner = memo(
  ({ a11yTitle, optionsData, value, setValue }: MultiSelectControlInnerProps) => {
    const options = useMemo(() => {
      return optionsData.map(data => {
        return {
          label: data.option.name,
          value: data.option.id,
          suffix: <Badge label={data.count} textColor="white" color={data.option.color ?? 'primary'} />
        }
      })
    }, [optionsData])

    return (
      <MultiSelectControl
        a11yTitle={a11yTitle}
        options={options}
        value={(value as any[])?.filter(v => v?.value !== 0 && v?.value !== '*' && v?.value !== null)}
        onChange={val => {
          // need to preserve none checked if it was also selected
          if (!val || val.length === 0) {
            // @ts-ignore TODO: investigate
            setValue(prevVal => (prevVal?.includes(0) ? [0] : undefined))
          } else {
            const nextVals = val?.map(v => v.value)
            // @ts-ignore TODO: investigate
            setValue(prevVal => (prevVal?.includes(0) ? [0, ...nextVals] : nextVals))
          }
        }}
        additionalControls={<AnyOrNoneControls value={value} setValue={setValue} />}
        plain
      />
    )
  },
  (prevProps, nextProps) => {
    return (
      prevProps.a11yTitle === nextProps.a11yTitle &&
      isEqual(prevProps.optionsData, nextProps.optionsData) &&
      isEqual(prevProps.value, nextProps.value) &&
      prevProps.setValue === nextProps.setValue
    )
  }
)
