import { memo } from 'react'
import { useRecoilValue } from 'recoil'

import { CheckboxGroup } from '@cutover/react-ui'
import { FilterAccordionPanel } from '../shared/filter-accordion-panel'
import { globalConfig } from 'main/recoil/shared/global-config'
import { useRunbookVersionProperty, useStageFilter } from 'main/recoil/data-access'
import { RunbookFilterType } from 'main/services/tasks/filtering'
import { useLanguage } from 'main/services/hooks'

type RunbookStageFilterValueType = RunbookFilterType['stage']

export const StageGroup = memo(() => {
  const { t } = useLanguage('common', { keyPrefix: 'filter.runbook' })
  const run = useRunbookVersionProperty({ attribute: 'run' })
  const taskStages = useRecoilValue(globalConfig).taskStageTypes
  const [runbookStages, setRunbookStages] = useStageFilter()

  return run ? (
    <FilterAccordionPanel label={t('stage')} applied={!!runbookStages?.length}>
      <CheckboxGroup
        plain
        a11yTitle={t('stage')}
        options={taskStages.map(stage => ({
          value: stage.id,
          label: stage.label
        }))}
        value={runbookStages}
        onChange={(event: any) => event && setRunbookStages(event.value as RunbookStageFilterValueType)}
      />
    </FilterAccordionPanel>
  ) : null
})
