import { selector, selectorFamily } from 'recoil'

import { runbookVersionState } from './runbook-version'
import { Run } from 'main/services/queries/types'

// :!: caution :!: .run is undefined it not in a run
export const runState = selector<Run | undefined>({
  key: 'runbookVersion:run',
  get: ({ get }) => get(runbookVersionState).run ?? undefined
})

export const runPropertyState = selectorFamily<Run[keyof Run] | undefined, { attribute: keyof Run }>({
  key: 'runbookVersion:run:attribute',
  get:
    ({ attribute }) =>
    ({ get }) =>
      get(runState)?.[attribute]
})
