import parse from 'html-react-parser'
import styled from 'styled-components'
import { eventManager } from 'event-manager'

import { Box, IconName, Text, themeColor } from '@cutover/react-ui'
import { ActivityConfig, ActivityVM } from 'main/services/queries/use-activities'
import { formatActivityDetailsValue, useActivityProperties } from './activity-helper'
import { ActivityInsights } from './activity'
import { useAppsTimezoneValue } from 'main/recoil/data-access'

type ActivityHeaderProps = {
  activity: ActivityVM
  activityConfig: ActivityConfig[]
  previousActivity: ActivityVM
  index: number
  active: boolean
  setActive: (index: number | null) => void
  time: string
}

type SubjectProps = {
  text: string
  internalIds?: number[]
}

export const ActivityHeader = ({ activity, activityConfig, time, index, active, setActive }: ActivityHeaderProps) => {
  const { activityIcon, subject, action, activistName } = useActivityProperties(activity, activityConfig)
  const commentIsDeleted =
    (activity.key === 'runbook.commented' || activity.key === 'task.commented') && activity.display?.reason
  const timezone = useAppsTimezoneValue()
  const handleSetFilters = (internalIds?: number[]) => {
    if (active) {
      eventManager.emit('filter-task-list-by-internal-ids', { internalIds: [] })
      setActive(null)
    } else {
      eventManager.emit('filter-task-list-by-internal-ids', { internalIds })
      setActive(index)
    }
  }

  return (
    <Box
      direction="row"
      gap="xsmall"
      pad={{ vertical: '4px' }}
      css={commentIsDeleted && !activity.parent ? 'opacity: 0.5' : ''}
      align="center"
      data-testid="activity-info"
    >
      <ActivityInsights time={time} icon={activityIcon as IconName} featured={activity.featured} />
      <Text
        truncate="tip"
        as="div"
        css="flex: 1; white-space: nowrap; text-overflow: ellipsis;
  overflow: hidden;"
      >
        <Text weight="bold">{activistName} </Text>
        {activity.display?.inline_message
          ? parse(formatActivityDetailsValue(activity.display?.inline_message, timezone, true))
          : renderSubject(subject, action, handleSetFilters, active)}
      </Text>
    </Box>
  )
}

const renderSubject = (
  subject: SubjectProps,
  action: string | undefined,
  handleSetFilters: Function,
  active: boolean
) => {
  return (
    <>
      <Text>{action} </Text>
      {subject.internalIds ? (
        <StyledLink active={!!active} onClick={() => handleSetFilters(subject.internalIds)}>
          {subject.text}
        </StyledLink>
      ) : (
        <Text weight="bold">{subject.text}</Text>
      )}
    </>
  )
}

// TODO this needs to be a react ui comp
export const StyledLink = styled(Text)<{ active?: boolean }>`
  color: ${themeColor('primary')};
  font-weight: ${({ active }) => (active ? 600 : 400)};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
