import { memo } from 'react'
import { useRecoilValue } from 'recoil'

import { CheckboxGroup } from '@cutover/react-ui'
import { FilterAccordionPanel } from '../shared/filter-accordion-panel'
import { camelToSnakeCase } from 'main/components/shared/filter/filter-hooks'
import { useCompletionTypeFilter, useRunbookVersionProperty } from 'main/recoil/data-access'
import { RunbookFilterType } from 'main/services/tasks/filtering'
import { globalConfig } from 'main/recoil/shared/global-config'
import { useLanguage } from 'main/services/hooks'

type RunbookCompletionTypeFilterValueType = RunbookFilterType['ct']

export const CompletionGroup = memo(() => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  const run = useRunbookVersionProperty({ attribute: 'run' })
  const completionTypes = useRecoilValue(globalConfig).completionTypes
  const [runbookCompletionTypes, setRunbookCompletionTypes] = useCompletionTypeFilter()

  return run ? (
    <FilterAccordionPanel label={t('completionType')} applied={!!runbookCompletionTypes?.length}>
      <CheckboxGroup
        plain
        a11yTitle={t('completionType')}
        options={Object.keys(completionTypes).map(key => ({
          value: camelToSnakeCase(key),
          label: completionTypes[key].name
        }))}
        value={runbookCompletionTypes}
        onChange={(event: any) =>
          event && setRunbookCompletionTypes(event.value as unknown as RunbookCompletionTypeFilterValueType)
        }
      />
    </FilterAccordionPanel>
  ) : null
})
