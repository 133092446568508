import { atom, selector } from 'recoil'

import { runbookIdState } from '../runbook/runbook'
import { runbookVersionIdState } from '../runbook-version/runbook-version'
import { deleteTasks } from 'main/services/queries/use-tasks'

export const tasksDeleteIdsAtom = atom<number[] | undefined>({
  key: 'tasks:delete:ids',
  default: undefined
})

export const tasksDeleteRequestState = selector({
  key: 'tasks:delete:request',
  get: async ({ get }) => {
    const tasksToDelete = get(tasksDeleteIdsAtom)
    const runbookId = get(runbookIdState)
    const versionId = get(runbookVersionIdState)

    if (!tasksToDelete || !runbookId || !versionId) return

    return await deleteTasks(runbookId, versionId, tasksToDelete)
  }
})
