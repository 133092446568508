import { memo, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { AccordionPanel, Message, Pill, RadioboxGroup } from '@cutover/react-ui'
import { RunbookEditFormType } from './runbook-edit-form'
import { useLanguage } from 'main/services/hooks'
import { CustomField, RTA_STATUSES, RunbookEditRunbook } from 'main/services/queries/types'
import { DurationPickerField, SelectField, TextAreaField } from '../../form'

type RunbookEditRtoSettingsPanelProps = {
  runbook: RunbookEditRunbook
  customFields: CustomField[]
  disabled?: boolean
  readOnly?: boolean
}

export const RunbookEditRtoPanel = memo(
  ({ runbook, disabled, readOnly, customFields }: RunbookEditRtoSettingsPanelProps) => {
    const { t } = useLanguage('runbooks', { keyPrefix: 'fields' })
    const [rtoSource, _setRtoSource] = useState(runbook.rto_source_id ? 'choose_field' : 'define_manually')
    const [rtaStatus, _setRtaStatus] = useState(initialRtaStatus)
    const { setValue, getValues } = useFormContext<RunbookEditFormType>()

    const setRtoSource = (rtoSource: string) => {
      if (rtoSource === 'choose_field') {
        setValue('runbook.rto', null)
      } else {
        setValue('runbook.rto_source_id', null)
      }

      _setRtoSource(rtoSource)
    }

    return (
      <AccordionPanel
        label={t('rtoRta.label')}
        icon="target"
        iconColor={runbook.project.color}
        labelSuffix={
          <Pill css="margin-left: 4px; flex-shrink: 0;" label={rtaStatus.name} color={rtaStatus.colour} size="small" />
        }
      >
        <RadioboxGroup
          name="toggle_rto_source"
          direction="column"
          label="RTO Source"
          disabled={disabled}
          options={[
            { value: 'define_manually', label: t('rtoRta.manualSource') },
            { value: 'choose_field', label: t('rtoRta.fieldSource') }
          ]}
          defaultValue={rtoSource}
          onChange={event => setRtoSource(event.target.value)}
        />
        {rtoSource === 'define_manually' && (
          <DurationPickerField<RunbookEditFormType>
            label={t('rtoRta.rto.label')}
            disabled={disabled}
            readOnly={readOnly}
            value={runbook.rto || undefined}
            name="runbook.rto"
          />
        )}
        {rtoSource === 'choose_field' && (
          <SelectField
            required={rtoIsRequired()}
            name="runbook.rto_source_id"
            label={t('rtoRta.rtoSource.label')}
            options={
              customFields ? customFields.map(customField => ({ label: customField.name, value: customField.id })) : []
            }
          />
        )}
        <TextAreaField<RunbookEditFormType>
          label={t('rtoRta.startTask.label')}
          required={rtoIsRequired()}
          readOnly={readOnly}
          disabled={disabled}
          defaultValue={runbook.current_version.rto_start_task_id?.toString()}
          name="runbook.runbook_versions_attributes.0.rto_start_task_id"
        />
        <TextAreaField<RunbookEditFormType>
          label={t('rtoRta.endTask.label')}
          required={rtoIsRequired()}
          readOnly={readOnly}
          disabled={disabled}
          defaultValue={runbook.current_version.rto_end_task_id?.toString()}
          name="runbook.runbook_versions_attributes.0.rto_end_task_id"
        />
        {rtaStatus.name === RTA_STATUSES['pending'].name && (
          <Message type="info">Actual timings will be recorded when this template is run</Message>
        )}
      </AccordionPanel>
    )

    function initialRtaStatus() {
      if (runbook.rta_results.length > 0) {
        return runbook.rta_results[0] > 10 ? RTA_STATUSES['fail'] : RTA_STATUSES['pass']
      } else if (runbook.rto && runbook.current_version.rto_start_task_id && runbook.current_version.rto_end_task_id) {
        return RTA_STATUSES['pending']
      } else return RTA_STATUSES['missing']
    }

    function rtoIsRequired() {
      return (
        (getValues([
          'runbook.rto_source_id',
          'runbook.runbook_versions_attributes.0.rto_start_task_id',
          'runbook.runbook_versions_attributes.0.rto_end_task_id'
        ]).filter(el => el !== null).length > 0 &&
          getValues('runbook.rto') !== null) ||
        getValues('runbook.rto_source_id') !== 0
      )
    }
  }
)
